export const countries = [
  { id: "1", name: "Nepal" },
  { id: "2", name: "Pakistan" },
  { id: "3", name: "India" },
  { id: "4", name: "Philippine" },
  { id: "5", name: "Sri Lanka" },
  { id: "6", name: "Bangladesh" },
];

export const allCountry = [
  { id: "1", name: "Afghanistan" },
  { id: "2", name: "Albania" },
  { id: "3", name: "Algeria" },
  { id: "4", name: "Andorra" },
  { id: "5", name: "Angola" },
  { id: "6", name: "Antigua and Barbuda" },
  { id: "7", name: "Argentina" },
  { id: "8", name: "Armenia" },
  { id: "9", name: "Australia" },
  { id: "10", name: "Austria" },
  { id: "11", name: "Azerbaijan" },
  { id: "12", name: "Bahamas" },
  { id: "13", name: "Bahrain" },
  { id: "14", name: "Bangladesh" },
  { id: "15", name: "Barbados" },
  { id: "16", name: "Belarus" },
  { id: "17", name: "Belgium" },
  { id: "18", name: "Belize" },
  { id: "19", name: "Benin" },
  { id: "20", name: "Bhutan" },
  { id: "21", name: "Bolivia" },
  { id: "22", name: "Bosnia and Herzegovina" },
  { id: "23", name: "Botswana" },
  { id: "24", name: "Brazil" },
  { id: "25", name: "Brunei" },
  { id: "26", name: "Bulgaria" },
  { id: "27", name: "Burkina Faso" },
  { id: "28", name: "Burundi" },
  { id: "29", name: "Cabo Verde" },
  { id: "30", name: "Cambodia" },
  { id: "31", name: "Cameroon" },
  { id: "32", name: "Canada" },
  { id: "33", name: "Central African Republic" },
  { id: "34", name: "Chad" },
  { id: "35", name: "Chile" },
  { id: "36", name: "China" },
  { id: "37", name: "Colombia" },
  { id: "38", name: "Comoros" },
  { id: "39", name: "Congo (Congo-Brazzaville)" },
  { id: "40", name: "Costa Rica" },
  { id: "41", name: "Croatia" },
  { id: "42", name: "Cuba" },
  { id: "43", name: "Cyprus" },
  { id: "44", name: "Czech Republic" },
  { id: "45", name: "Democratic Republic of the Congo" },
  { id: "46", name: "Denmark" },
  { id: "47", name: "Djibouti" },
  { id: "48", name: "Dominica" },
  { id: "49", name: "Dominican Republic" },
  { id: "50", name: "East Timor" },
  { id: "51", name: "Ecuador" },
  { id: "52", name: "Egypt" },
  { id: "53", name: "El Salvador" },
  { id: "54", name: "Equatorial Guinea" },
  { id: "55", name: "Eritrea" },
  { id: "56", name: "Estonia" },
  { id: "57", name: "Eswatini" },
  { id: "58", name: "Ethiopia" },
  { id: "59", name: "Fiji" },
  { id: "60", name: "Finland" },
  { id: "61", name: "France" },
  { id: "62", name: "Gabon" },
  { id: "63", name: "Gambia" },
  { id: "64", name: "Georgia" },
  { id: "65", name: "Germany" },
  { id: "66", name: "Ghana" },
  { id: "67", name: "Greece" },
  { id: "68", name: "Grenada" },
  { id: "69", name: "Guatemala" },
  { id: "70", name: "Guinea" },
  { id: "71", name: "Guinea-Bissau" },
  { id: "72", name: "Guyana" },
  { id: "73", name: "Haiti" },
  { id: "74", name: "Honduras" },
  { id: "75", name: "Hungary" },
  { id: "76", name: "Iceland" },
  { id: "77", name: "India" },
  { id: "78", name: "Indonesia" },
  { id: "79", name: "Iran" },
  { id: "80", name: "Iraq" },
  { id: "81", name: "Ireland" },
  { id: "82", name: "Israel" },
  { id: "83", name: "Italy" },
  { id: "84", name: "Jamaica" },
  { id: "85", name: "Japan" },
  { id: "86", name: "Jordan" },
  { id: "87", name: "Kazakhstan" },
  { id: "88", name: "Kenya" },
  { id: "89", name: "Kiribati" },
  { id: "90", name: "Kuwait" },
  { id: "91", name: "Kyrgyzstan" },
  { id: "92", name: "Laos" },
  { id: "93", name: "Latvia" },
  { id: "94", name: "Lebanon" },
  { id: "95", name: "Lesotho" },
  { id: "96", name: "Liberia" },
  { id: "97", name: "Libya" },
  { id: "98", name: "Liechtenstein" },
  { id: "99", name: "Lithuania" },
  { id: "100", name: "Luxembourg" },
  { id: "101", name: "Madagascar" },
  { id: "102", name: "Malawi" },
  { id: "103", name: "Malaysia" },
  { id: "104", name: "Maldives" },
  { id: "105", name: "Mali" },
  { id: "106", name: "Malta" },
  { id: "107", name: "Marshall Islands" },
  { id: "108", name: "Mauritania" },
  { id: "109", name: "Mauritius" },
  { id: "110", name: "Mexico" },
  { id: "111", name: "Micronesia" },
  { id: "112", name: "Moldova" },
  { id: "113", name: "Monaco" },
  { id: "114", name: "Mongolia" },
  { id: "115", name: "Montenegro" },
  { id: "116", name: "Morocco" },
  { id: "117", name: "Mozambique" },
  { id: "118", name: "Myanmar" },
  { id: "119", name: "Namibia" },
  { id: "120", name: "Nauru" },
  { id: "121", name: "Nepal" },
  { id: "122", name: "Netherlands" },
  { id: "123", name: "New Zealand" },
  { id: "124", name: "Nicaragua" },
  { id: "125", name: "Niger" },
  { id: "126", name: "Nigeria" },
  { id: "127", name: "North Korea" },
  { id: "128", name: "North Macedonia" },
  { id: "129", name: "Norway" },
  { id: "130", name: "Oman" },
  { id: "131", name: "Pakistan" },
  { id: "132", name: "Palau" },
  { id: "133", name: "Panama" },
  { id: "134", name: "Papua New Guinea" },
  { id: "135", name: "Paraguay" },
  { id: "136", name: "Peru" },
  { id: "137", name: "Philippines" },
  { id: "138", name: "Poland" },
  { id: "139", name: "Portugal" },
  { id: "140", name: "Qatar" },
  { id: "141", name: "Romania" },
  { id: "142", name: "Russia" },
  { id: "143", name: "Rwanda" },
  { id: "144", name: "Saint Kitts and Nevis" },
  { id: "145", name: "Saint Lucia" },
  { id: "146", name: "Saint Vincent and the Grenadines" },
  { id: "147", name: "Samoa" },
  { id: "148", name: "San Marino" },
  { id: "149", name: "Sao Tome and Principe" },
  { id: "150", name: "Saudi Arabia" },
  { id: "151", name: "Senegal" },
  { id: "152", name: "Serbia" },
  { id: "153", name: "Seychelles" },
  { id: "154", name: "Sierra Leone" },
  { id: "155", name: "Singapore" },
  { id: "156", name: "Slovakia" },
  { id: "157", name: "Slovenia" },
  { id: "158", name: "Solomon Islands" },
  { id: "159", name: "Somalia" },
  { id: "160", name: "South Africa" },
  { id: "161", name: "South Korea" },
  { id: "162", name: "South Sudan" },
  { id: "163", name: "Spain" },
  { id: "164", name: "Sri Lanka" },
  { id: "165", name: "Sudan" },
  { id: "166", name: "Suriname" },
  { id: "167", name: "Sweden" },
  { id: "168", name: "Switzerland" },
  { id: "169", name: "Syria" },
  { id: "170", name: "Taiwan" },
  { id: "171", name: "Tajikistan" },
  { id: "172", name: "Tanzania" },
  { id: "173", name: "Thailand" },
  { id: "174", name: "Togo" },
  { id: "175", name: "Tonga" },
  { id: "176", name: "Trinidad and Tobago" },
  { id: "177", name: "Tunisia" },
  { id: "178", name: "Turkey" },
  { id: "179", name: "Turkmenistan" },
  { id: "180", name: "Tuvalu" },
  { id: "181", name: "Uganda" },
  { id: "182", name: "Ukraine" },
  { id: "183", name: "United Arab Emirates" },
  { id: "184", name: "United Kingdom" },
  { id: "185", name: "United States" },
  { id: "186", name: "Uruguay" },
  { id: "187", name: "Uzbekistan" },
  { id: "188", name: "Vanuatu" },
  { id: "189", name: "Vatican City" },
  { id: "190", name: "Venezuela" },
  { id: "191", name: "Vietnam" },
  { id: "192", name: "Yemen" },
  { id: "193", name: "Zambia" },
  { id: "194", name: "Zimbabwe" },
];

export const state = [
  { id: "101", name: "Bagmati", country_id: "1" },
  { id: "102", name: "Gandaki", country_id: "1" },
  { id: "103", name: "Madhesh", country_id: "1" },
  { id: "104", name: "Koshi", country_id: "1" },
  { id: "105", name: "Lumbini", country_id: "1" },
  { id: "106", name: "Sudurpashchim", country_id: "1" },
  { id: "107", name: "Karnali", country_id: "1" },

  { id: "201", name: "Khyber Pakhtunkhwa", country_id: "2" },
  { id: "202", name: "Punjab", country_id: "2" },
  { id: "203", name: "Sindh", country_id: "2" },
  { id: "204", name: "Balochistan", country_id: "2" },
  { id: "205", name: "Azad Jammu and Kashmir", country_id: "2" },
  { id: "206", name: "Gilgit-Baltistan", country_id: "2" },
  { id: "207", name: "Islamabad Capital Territory", country_id: "2" },

  { id: "301", name: "Maharashtra", country_id: "3" },
  { id: "302", name: "West Bengal", country_id: "3" },
  { id: "303", name: "Uttar Pradesh", country_id: "3" },
  { id: "304", name: "Tamil Nadu", country_id: "3" },
  { id: "305", name: "Andhra Pradesh", country_id: "3" },
  { id: "306", name: "Arunachal Pradesh", country_id: "3" },
  { id: "307", name: "Assam", country_id: "3" },
  { id: "308", name: "Bihar", country_id: "3" },
  { id: "309", name: "Chhattisgarh", country_id: "3" },
  { id: "310", name: "Goa", country_id: "3" },
  { id: "311", name: "Gujarat", country_id: "3" },
  { id: "312", name: "Haryana", country_id: "3" },
  { id: "313", name: "Himachal Pradesh", country_id: "3" },
  { id: "314", name: "Jharkhand", country_id: "3" },
  { id: "315", name: "Karnataka", country_id: "3" },
  { id: "316", name: "Kerala", country_id: "3" },
  { id: "317", name: "Madhya Pradesh", country_id: "3" },
  { id: "318", name: "Manipur", country_id: "3" },
  { id: "319", name: "Meghalaya", country_id: "3" },
  { id: "320", name: "Mizoram", country_id: "3" },
  { id: "321", name: "Nagaland", country_id: "3" },
  { id: "322", name: "Odisha", country_id: "3" },
  { id: "323", name: "Punjab", country_id: "3" },
  { id: "324", name: "Rajasthan", country_id: "3" },
  { id: "325", name: "Sikkim", country_id: "3" },
  { id: "326", name: "Telangana", country_id: "3" },
  { id: "327", name: "Tripura", country_id: "3" },
  { id: "328", name: "Uttarakhand", country_id: "3" },
  { id: "329", name: "Andaman and Nicobar Islands", country_id: "3" },
  { id: "330", name: "Chandigarh", country_id: "3" },
  {
    id: "331",
    name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: "3",
  },
  { id: "332", name: "Lakshadweep", country_id: "3" },
  { id: "333", name: "Delhi (National Capital Territory)", country_id: "3" },
  { id: "334", name: "Puducherry", country_id: "3" },
  { id: "335", name: "Jammu and Kashmir", country_id: "3" },
  { id: "336", name: "Ladakh", country_id: "3" },

  { id: "401", name: "Abra", country_id: "4" },
  { id: "402", name: "Albay", country_id: "4" },
  { id: "403", name: "Apayao", country_id: "4" },
  { id: "404", name: "Aurora", country_id: "4" },
  { id: "405", name: "Bataan", country_id: "4" },
  { id: "406", name: "Batanes", country_id: "4" },
  { id: "407", name: "Batangas", country_id: "4" },
  { id: "408", name: "Benguet", country_id: "4" },
  { id: "409", name: "Bulacan", country_id: "4" },
  { id: "410", name: "Cagayan", country_id: "4" },
  { id: "411", name: "Camarines Norte", country_id: "4" },
  { id: "412", name: "Camarines Sur", country_id: "4" },
  { id: "413", name: "Catanduanes", country_id: "4" },
  { id: "414", name: "Cavite", country_id: "4" },
  { id: "415", name: "Ifugao", country_id: "4" },
  { id: "416", name: "Ilocos Norte", country_id: "4" },
  { id: "417", name: "Ilocos Sur", country_id: "4" },
  { id: "418", name: "Isabela", country_id: "4" },
  { id: "419", name: "Kalinga", country_id: "4" },
  { id: "420", name: "La Union", country_id: "4" },
  { id: "421", name: "Laguna", country_id: "4" },
  { id: "422", name: "Marinduque", country_id: "4" },
  { id: "423", name: "Masbate", country_id: "4" },
  { id: "424", name: "Mountain Province", country_id: "4" },
  { id: "425", name: "Nueva Ecija", country_id: "4" },
  { id: "426", name: "Nueva Vizcaya", country_id: "4" },
  { id: "427", name: "Occidental Mindoro", country_id: "4" },
  { id: "428", name: "Oriental Mindoro", country_id: "4" },
  { id: "429", name: "Palawan", country_id: "4" },
  { id: "430", name: "Pampanga", country_id: "4" },
  { id: "431", name: "Pangasinan", country_id: "4" },
  { id: "432", name: "Quezon", country_id: "4" },
  { id: "433", name: "Quirino", country_id: "4" },
  { id: "434", name: "Rizal", country_id: "4" },
  { id: "435", name: "Romblon", country_id: "4" },
  { id: "436", name: "Sorsogon", country_id: "4" },
  { id: "437", name: "Tarlac", country_id: "4" },
  { id: "438", name: "Zambales", country_id: "4" },
  { id: "439", name: "Aklan", country_id: "4" },
  { id: "440", name: "Antique", country_id: "4" },
  { id: "441", name: "Bohol", country_id: "4" },
  { id: "442", name: "Capiz", country_id: "4" },
  { id: "443", name: "Cebu", country_id: "4" },
  { id: "444", name: "Eastern Samar", country_id: "4" },
  { id: "445", name: "Guimaras", country_id: "4" },
  { id: "446", name: "Iloilo", country_id: "4" },
  { id: "447", name: "Leyte", country_id: "4" },
  { id: "448", name: "Negros Occidental", country_id: "4" },
  { id: "449", name: "Negros Oriental", country_id: "4" },
  { id: "450", name: "Northern Samar", country_id: "4" },
  { id: "451", name: "Samar", country_id: "4" },
  { id: "452", name: "Siquijor", country_id: "4" },
  { id: "453", name: "Southern Leyte", country_id: "4" },
  { id: "454", name: "Agusan del Norte", country_id: "4" },
  { id: "455", name: "Agusan del Sur", country_id: "4" },
  { id: "456", name: "Basilan", country_id: "4" },
  { id: "457", name: "Bukidnon", country_id: "4" },
  { id: "458", name: "Camiguin", country_id: "4" },
  { id: "459", name: "Compostela Valley", country_id: "4" },
  { id: "460", name: "Davao del Norte", country_id: "4" },
  { id: "461", name: "Davao del Sur", country_id: "4" },
  { id: "462", name: "Davao Occidental", country_id: "4" },
  { id: "463", name: "Davao Oriental", country_id: "4" },
  { id: "464", name: "Dinagat Islands", country_id: "4" },
  { id: "465", name: "Lanao del Norte", country_id: "4" },
  { id: "466", name: "Lanao del Sur", country_id: "4" },
  { id: "467", name: "Maguindanao", country_id: "4" },
  { id: "468", name: "Misamis Occidental", country_id: "4" },
  { id: "469", name: "Misamis Oriental", country_id: "4" },
  { id: "470", name: "North Cotabato", country_id: "4" },
  { id: "471", name: "Sarangani", country_id: "4" },
  { id: "472", name: "South Cotabato", country_id: "4" },
  { id: "473", name: "Sultan Kudarat", country_id: "4" },
  { id: "474", name: "Sulu", country_id: "4" },
  { id: "475", name: "Surigao del Norte", country_id: "4" },
  { id: "476", name: "Surigao del Sur", country_id: "4" },
  { id: "477", name: "Metro Manila (NCR)", country_id: "4" },
  { id: "478", name: "Tawi-Tawi", country_id: "4" },
  { id: "479", name: "Zamboanga del Norte", country_id: "4" },
  { id: "480", name: "Zamboanga del Sur", country_id: "4" },
  { id: "481", name: "Zamboanga Sibugay", country_id: "4" },

  { id: "501", name: "Central", country_id: "5" },
  { id: "502", name: "Eastern", country_id: "5" },
  { id: "503", name: "Northern", country_id: "5" },
  { id: "504", name: "North Central", country_id: "5" },
  { id: "505", name: "North Western", country_id: "5" },
  { id: "506", name: "Sabaragamuwa", country_id: "5" },
  { id: "507", name: "Southern", country_id: "5" },
  { id: "508", name: "Uva", country_id: "5" },
  { id: "509", name: "Western", country_id: "5" },

  { id: "601", name: "Dhaka", country_id: "6" },
  { id: "602", name: "Chattogram", country_id: "6" },
  { id: "603", name: "Khulna", country_id: "6" },
  { id: "604", name: "Rajshahi", country_id: "6" },
  { id: "605", name: "Sylhet", country_id: "6" },
  { id: "606", name: "Barishal", country_id: "6" },
  { id: "607", name: "Rangpur", country_id: "6" },
  { id: "608", name: "Mymensingh", country_id: "6" },
];

export const cities = [
  { id: "1001", name: "Bhaktapur", state_id: "101" },
  { id: "1002", name: "Chitwan", state_id: "101" },
  { id: "1003", name: "Dhading", state_id: "101" },
  { id: "1004", name: "Dolakha", state_id: "101" },
  { id: "1005", name: "Kathmandu", state_id: "101" },
  { id: "1006", name: "Kavrepalanchok", state_id: "101" },
  { id: "1007", name: "Lalitpur", state_id: "101" },
  { id: "1008", name: "Makwanpur", state_id: "101" },
  { id: "1009", name: "Nuwakot", state_id: "101" },
  { id: "1010", name: "Ramechhap", state_id: "101" },
  { id: "1011", name: "Rasuwa", state_id: "101" },
  { id: "1012", name: "Sindhuli", state_id: "101" },
  { id: "1013", name: "Sindhupalchok", state_id: "101" },
  { id: "1014", name: "Hetauda", state_id: "101" },
  { id: "1015", name: "Banepa", state_id: "101" },
  { id: "1016", name: "Dhulikhel", state_id: "101" },
  { id: "1017", name: "Bharatpur", state_id: "101" },
  { id: "1018", name: "Kirtipur", state_id: "101" },

  { id: "1019", name: "Baglung", state_id: "102" },
  { id: "1020", name: "Gorkha", state_id: "102" },
  { id: "1021", name: "Kaski", state_id: "102" },
  { id: "1022", name: "Lamjung", state_id: "102" },
  { id: "1023", name: "Manang", state_id: "102" },
  { id: "1024", name: "Mustang", state_id: "102" },
  { id: "1025", name: "Nawalpur", state_id: "102" },
  { id: "1026", name: "Parbat", state_id: "102" },
  { id: "1027", name: "Syangja", state_id: "102" },
  { id: "1028", name: "Pokhara", state_id: "102" },
  { id: "1029", name: "Besisahar", state_id: "102" },
  { id: "1030", name: "Damauli", state_id: "102" },
  { id: "1031", name: "Dhorpatan", state_id: "102" },
  { id: "1032", name: "Jomsom", state_id: "102" },
  { id: "1033", name: "Tansen", state_id: "102" },
  { id: "1034", name: "Beni", state_id: "102" },

  { id: "1035", name: "Bara", state_id: "103" },
  { id: "1036", name: "Dhanusha", state_id: "103" },
  { id: "1037", name: "Mahottari", state_id: "103" },
  { id: "1038", name: "Parsa", state_id: "103" },
  { id: "1039", name: "Rautahat", state_id: "103" },
  { id: "1040", name: "Saptari", state_id: "103" },
  { id: "1041", name: "Sarlahi", state_id: "103" },
  { id: "1042", name: "Siraha", state_id: "103" },
  { id: "1043", name: "Janakpur", state_id: "103" },
  { id: "1044", name: "Gaur", state_id: "103" },
  { id: "1045", name: "Birgunj", state_id: "103" },
  { id: "1046", name: "Lahan", state_id: "103" },
  { id: "1047", name: "Malangwa", state_id: "103" },
  { id: "1048", name: "Rajbiraj", state_id: "103" },
  { id: "1049", name: "Kalaiya", state_id: "103" },

  { id: "1050", name: "Bhojpur", state_id: "104" },
  { id: "1051", name: "Dhankuta", state_id: "104" },
  { id: "1052", name: "Ilam", state_id: "104" },
  { id: "1053", name: "Jhapa", state_id: "104" },
  { id: "1054", name: "Khotang", state_id: "104" },
  { id: "1055", name: "Morang", state_id: "104" },
  { id: "1056", name: "Okhaldhunga", state_id: "104" },
  { id: "1057", name: "Panchthar", state_id: "104" },
  { id: "1058", name: "Sankhuwasabha", state_id: "104" },
  { id: "1059", name: "Solukhumbu", state_id: "104" },
  { id: "1060", name: "Sunsari", state_id: "104" },
  { id: "1061", name: "Taplejung", state_id: "104" },
  { id: "1062", name: "Terhathum", state_id: "104" },
  { id: "1063", name: "Udayapur", state_id: "104" },
  { id: "1064", name: "Biratnagar", state_id: "104" },
  { id: "1065", name: "Dharan", state_id: "104" },
  { id: "1066", name: "Inaruwa", state_id: "104" },
  { id: "1067", name: "Itahari", state_id: "104" },
  { id: "1068", name: "Birtamod", state_id: "104" },
  { id: "1069", name: "Rajbiraj", state_id: "104" },

  { id: "1070", name: "Arghakhanchi", state_id: "105" },
  { id: "1071", name: "Banke", state_id: "105" },
  { id: "1072", name: "Bardiya", state_id: "105" },
  { id: "1073", name: "Dang", state_id: "105" },
  { id: "1074", name: "Gulmi", state_id: "105" },
  { id: "1075", name: "Kapilvastu", state_id: "105" },
  { id: "1076", name: "Palpa", state_id: "105" },
  { id: "1077", name: "Pyuthan", state_id: "105" },
  { id: "1078", name: "Rolpa", state_id: "105" },
  { id: "1079", name: "Rukum (East)", state_id: "105" },
  { id: "1080", name: "Rupandehi", state_id: "105" },
  { id: "1081", name: "Butwal", state_id: "105" },
  { id: "1082", name: "Nepalgunj", state_id: "105" },
  { id: "1083", name: "Bhairahawa", state_id: "105" },
  { id: "1084", name: "Tulsipur", state_id: "105" },
  { id: "1085", name: "Ghorahi", state_id: "105" },
  { id: "1086", name: "Sandhikharka", state_id: "105" },
  { id: "1087", name: "Taulihawa", state_id: "105" },

  { id: "1088", name: "Achham", state_id: "106" },
  { id: "1089", name: "Baitadi", state_id: "106" },
  { id: "1090", name: "Bajhang", state_id: "106" },
  { id: "1091", name: "Bajura", state_id: "106" },
  { id: "1092", name: "Darchula", state_id: "106" },
  { id: "1093", name: "Doti", state_id: "106" },
  { id: "1094", name: "Kailali", state_id: "106" },
  { id: "1095", name: "Kanchanpur", state_id: "106" },
  { id: "1096", name: "Dhangadhi", state_id: "106" },
  { id: "1097", name: "Mahendranagar", state_id: "106" },
  { id: "1098", name: "Tikapur", state_id: "106" },
  { id: "1099", name: "Attariya", state_id: "106" },
  { id: "1100", name: "Dipayal", state_id: "106" },

  { id: "1101", name: "Dolpa", state_id: "107" },
  { id: "1102", name: "Humla", state_id: "107" },
  { id: "1103", name: "Jajarkot", state_id: "107" },
  { id: "1104", name: "Jumla", state_id: "107" },
  { id: "1105", name: "Kalaiya", state_id: "107" },
  { id: "1106", name: "Mugu", state_id: "107" },
  { id: "1107", name: "Rukum (West)", state_id: "107" },
  { id: "1108", name: "Salyan", state_id: "107" },
  { id: "1109", name: "Surkhet", state_id: "107" },
  { id: "1110", name: "Birendranagar", state_id: "107" },
  { id: "1111", name: "Nepalgunj", state_id: "107" },

  { id: "1112", name: "Bannu", state_id: "201" },
  { id: "1113", name: "Batagram", state_id: "201" },
  { id: "1114", name: "Buner", state_id: "201" },
  { id: "1115", name: "Charsadda", state_id: "201" },
  { id: "1116", name: "Chitral", state_id: "201" },
  { id: "1117", name: "Dera Ismail Khan", state_id: "201" },
  { id: "1118", name: "Hangu", state_id: "201" },
  { id: "1119", name: "Haripur", state_id: "201" },
  { id: "1120", name: "Khyber", state_id: "201" },
  { id: "1121", name: "Kohat", state_id: "201" },
  { id: "1122", name: "Kohistan", state_id: "201" },
  { id: "1123", name: "Lakki Marwat", state_id: "201" },
  { id: "1124", name: "Lower Dir", state_id: "201" },
  { id: "1125", name: "Mardan", state_id: "201" },
  { id: "1126", name: "Nowshera", state_id: "201" },
  { id: "1127", name: "Peshawar", state_id: "201" },
  { id: "1128", name: "Shangla", state_id: "201" },
  { id: "1129", name: "Swabi", state_id: "201" },
  { id: "1130", name: "Swat", state_id: "201" },
  { id: "1131", name: "Tank", state_id: "201" },
  { id: "1132", name: "Upper Dir", state_id: "201" },
  { id: "1133", name: "Abbottabad", state_id: "201" },

  { id: "1134", name: "Attock", state_id: "202" },
  { id: "1135", name: "Bahawalnagar", state_id: "202" },
  { id: "1136", name: "Bahawalpur", state_id: "202" },
  { id: "1137", name: "Bhakkar", state_id: "202" },
  { id: "1138", name: "Chakwal", state_id: "202" },
  { id: "1139", name: "Chiniot", state_id: "202" },
  { id: "1140", name: "Faisalabad", state_id: "202" },
  { id: "1141", name: "Ferozepur", state_id: "202" },
  { id: "1142", name: "Gujranwala", state_id: "202" },
  { id: "1143", name: "Gujrat", state_id: "202" },
  { id: "1144", name: "Hafizabad", state_id: "202" },
  { id: "1145", name: "Jhang", state_id: "202" },
  { id: "1146", name: "Jhelum", state_id: "202" },
  { id: "1147", name: "Kasur", state_id: "202" },
  { id: "1148", name: "Khanewal", state_id: "202" },
  { id: "1149", name: "Kharian", state_id: "202" },
  { id: "1150", name: "Khushab", state_id: "202" },
  { id: "1151", name: "Lahore", state_id: "202" },
  { id: "1152", name: "Lodhran", state_id: "202" },
  { id: "1153", name: "Mandi Bahauddin", state_id: "202" },
  { id: "1154", name: "Mianwali", state_id: "202" },
  { id: "1155", name: "Multan", state_id: "202" },
  { id: "1156", name: "Narowal", state_id: "202" },
  { id: "1157", name: "Okara", state_id: "202" },
  { id: "1158", name: "Pakpattan", state_id: "202" },
  { id: "1159", name: "Rajanpur", state_id: "202" },
  { id: "1160", name: "Rawalpindi", state_id: "202" },
  { id: "1161", name: "Sahiwal", state_id: "202" },
  { id: "1162", name: "Sargodha", state_id: "202" },
  { id: "1163", name: "Sheikhupura", state_id: "202" },
  { id: "1164", name: "Sialkot", state_id: "202" },
  { id: "1165", name: "Toba Tek Singh", state_id: "202" },
  { id: "1166", name: "Vehari", state_id: "202" },

  { id: "1167", name: "Badin", state_id: "203" },
  { id: "1168", name: "Jacobabad", state_id: "203" },
  { id: "1169", name: "Jamshoro", state_id: "203" },
  { id: "1170", name: "Karachi", state_id: "203" },
  { id: "1171", name: "Kashmore", state_id: "203" },
  { id: "1172", name: "Khairpur", state_id: "203" },
  { id: "1173", name: "Larkana", state_id: "203" },
  { id: "1174", name: "Matiari", state_id: "203" },
  { id: "1175", name: "Mirpurkhas", state_id: "203" },
  { id: "1176", name: "Naushehro Feroze", state_id: "203" },
  { id: "1177", name: "Qambar Shahdadkot", state_id: "203" },
  { id: "1178", name: "Shaheed Benazirabad", state_id: "203" },
  { id: "1179", name: "Sanghar", state_id: "203" },
  { id: "1180", name: "Shikarpur", state_id: "203" },
  { id: "1181", name: "Sukkur", state_id: "203" },
  { id: "1182", name: "Tando Allahyar", state_id: "203" },
  { id: "1183", name: "Tando Muhammad Khan", state_id: "203" },
  { id: "1184", name: "Tharparkar", state_id: "203" },
  { id: "1185", name: "Umerkot", state_id: "203" },
  { id: "1186", name: "Hyderabad", state_id: "203" },
  { id: "1187", name: "Thatta", state_id: "203" },

  { id: "1188", name: "Awaran", state_id: "204" },
  { id: "1189", name: "Barkhan", state_id: "204" },
  { id: "1190", name: "Chagai", state_id: "204" },
  { id: "1191", name: "Dera Bugti", state_id: "204" },
  { id: "1192", name: "Harnai", state_id: "204" },
  { id: "1193", name: "Jafarabad", state_id: "204" },
  { id: "1194", name: "Jhal Magsi", state_id: "204" },
  { id: "1195", name: "Kachhi", state_id: "204" },
  { id: "1196", name: "Kalat", state_id: "204" },
  { id: "1197", name: "Kech", state_id: "204" },
  { id: "1198", name: "Kharan", state_id: "204" },
  { id: "1199", name: "Khuzdar", state_id: "204" },
  { id: "1200", name: "Loralai", state_id: "204" },
  { id: "1201", name: "Mastung", state_id: "204" },
  { id: "1202", name: "Naseerabad", state_id: "204" },
  { id: "1203", name: "Nushki", state_id: "204" },
  { id: "1204", name: "Pishin", state_id: "204" },
  { id: "1205", name: "Quetta", state_id: "204" },
  { id: "1206", name: "Sibi", state_id: "204" },
  { id: "1207", name: "Washuk", state_id: "204" },
  { id: "1208", name: "Zhob", state_id: "204" },
  { id: "1209", name: "Ziarat", state_id: "204" },
  { id: "1210", name: "Gwadar", state_id: "204" },

  { id: "1211", name: "Bhimber", state_id: "205" },
  { id: "1212", name: "Kotli", state_id: "205" },
  { id: "1213", name: "Mirpur", state_id: "205" },
  { id: "1214", name: "Poonch", state_id: "205" },
  { id: "1215", name: "Rawalakot", state_id: "205" },
  { id: "1216", name: "Neelum", state_id: "205" },
  { id: "1217", name: "Muzaffarabad", state_id: "205" },

  { id: "1218", name: "Gilgit", state_id: "206" },
  { id: "1219", name: "Skardu", state_id: "206" },
  { id: "1220", name: "Diamer", state_id: "206" },
  { id: "1221", name: "Hunza", state_id: "206" },
  { id: "1222", name: "Ghanche", state_id: "206" },
  { id: "1223", name: "Ghizer", state_id: "206" },

  { id: "1224", name: "Islamabad", state_id: "207" },

  { id: "1225", name: "Mumbai", state_id: "301" },
  { id: "1226", name: "Pune", state_id: "301" },
  { id: "1227", name: "Nagpur", state_id: "301" },
  { id: "1228", name: "Thane", state_id: "301" },
  { id: "1229", name: "Nashik", state_id: "301" },
  { id: "1230", name: "Aurangabad", state_id: "301" },
  { id: "1231", name: "Solapur", state_id: "301" },
  { id: "1232", name: "Jalgaon", state_id: "301" },
  { id: "1233", name: "Kolhapur", state_id: "301" },
  { id: "1234", name: "Amravati", state_id: "301" },
  { id: "1235", name: "Nanded", state_id: "301" },
  { id: "1236", name: "Satara", state_id: "301" },

  { id: "1237", name: "Kolkata", state_id: "302" },
  { id: "1238", name: "Howrah", state_id: "302" },
  { id: "1239", name: "Darjeeling", state_id: "302" },
  { id: "1240", name: "North 24 Parganas", state_id: "302" },
  { id: "1241", name: "South 24 Parganas", state_id: "302" },
  { id: "1242", name: "Paschim Medinipur", state_id: "302" },
  { id: "1243", name: "Purba Medinipur", state_id: "302" },
  { id: "1244", name: "Murshidabad", state_id: "302" },
  { id: "1245", name: "Nadia", state_id: "302" },
  { id: "1246", name: "Birbhum", state_id: "302" },
  { id: "1247", name: "Bardhaman", state_id: "302" },
  { id: "1248", name: "Alipurduar", state_id: "302" },

  { id: "1249", name: "Lucknow", state_id: "303" },
  { id: "1250", name: "Kanpur", state_id: "303" },
  { id: "1251", name: "Agra", state_id: "303" },
  { id: "1252", name: "Varanasi", state_id: "303" },
  { id: "1253", name: "Meerut", state_id: "303" },
  { id: "1254", name: "Allahabad", state_id: "303" },
  { id: "1255", name: "Noida", state_id: "303" },
  { id: "1256", name: "Ghaziabad", state_id: "303" },
  { id: "1257", name: "Gorakhpur", state_id: "303" },
  { id: "1258", name: "Bareilly", state_id: "303" },
  { id: "1259", name: "Aligarh", state_id: "303" },
  { id: "1260", name: "Jhansi", state_id: "303" },

  { id: "1261", name: "Chennai", state_id: "304" },
  { id: "1262", name: "Coimbatore", state_id: "304" },
  { id: "1263", name: "Madurai", state_id: "304" },
  { id: "1264", name: "Tiruchirappalli", state_id: "304" },
  { id: "1265", name: "Salem", state_id: "304" },
  { id: "1266", name: "Tirunelveli", state_id: "304" },
  { id: "1267", name: "Vellore", state_id: "304" },
  { id: "1268", name: "Erode", state_id: "304" },
  { id: "1269", name: "Thoothukudi", state_id: "304" },
  { id: "1270", name: "Dindigul", state_id: "304" },
  { id: "1271", name: "Thanjavur", state_id: "304" },
  { id: "1272", name: "Kanchipuram", state_id: "304" },

  { id: "1273", name: "Visakhapatnam", state_id: "305" },
  { id: "1274", name: "Vijayawada", state_id: "305" },
  { id: "1275", name: "Guntur", state_id: "305" },
  { id: "1276", name: "Nellore", state_id: "305" },
  { id: "1277", name: "Kurnool", state_id: "305" },
  { id: "1278", name: "Kadapa", state_id: "305" },
  { id: "1279", name: "Tirupati", state_id: "305" },
  { id: "1280", name: "Rajahmundry", state_id: "305" },
  { id: "1281", name: "Kakinada", state_id: "305" },
  { id: "1282", name: "Anantapur", state_id: "305" },
  { id: "1283", name: "Chittoor", state_id: "305" },
  { id: "1284", name: "Ongole", state_id: "305" },

  { id: "1285", name: "Itanagar", state_id: "306" },
  { id: "1286", name: "Tawang", state_id: "306" },
  { id: "1287", name: "West Kameng", state_id: "306" },
  { id: "1288", name: "East Kameng", state_id: "306" },
  { id: "1289", name: "Papum Pare", state_id: "306" },
  { id: "1290", name: "Lower Subansiri", state_id: "306" },
  { id: "1291", name: "Upper Subansiri", state_id: "306" },
  { id: "1292", name: "Kurung Kumey", state_id: "306" },
  { id: "1293", name: "Lohit", state_id: "306" },
  { id: "1294", name: "Anjaw", state_id: "306" },
  { id: "1295", name: "Changlang", state_id: "306" },
  { id: "1296", name: "Tirap", state_id: "306" },

  { id: "1297", name: "Guwahati", state_id: "307" },
  { id: "1298", name: "Silchar", state_id: "307" },
  { id: "1299", name: "Dibrugarh", state_id: "307" },
  { id: "1300", name: "Jorhat", state_id: "307" },
  { id: "1301", name: "Nagaon", state_id: "307" },
  { id: "1302", name: "Tinsukia", state_id: "307" },
  { id: "1303", name: "Tezpur", state_id: "307" },
  { id: "1304", name: "Bongaigaon", state_id: "307" },
  { id: "1305", name: "Barpeta", state_id: "307" },
  { id: "1306", name: "Karimganj", state_id: "307" },
  { id: "1307", name: "Golaghat", state_id: "307" },
  { id: "1308", name: "Dhemaji", state_id: "307" },

  { id: "1309", name: "Patna", state_id: "308" },
  { id: "1310", name: "Gaya", state_id: "308" },
  { id: "1311", name: "Bhagalpur", state_id: "308" },
  { id: "1312", name: "Muzaffarpur", state_id: "308" },
  { id: "1313", name: "Darbhanga", state_id: "308" },
  { id: "1314", name: "Purnia", state_id: "308" },
  { id: "1315", name: "Begusarai", state_id: "308" },
  { id: "1316", name: "Ara", state_id: "308" },
  { id: "1317", name: "Katihar", state_id: "308" },
  { id: "1318", name: "Chapra", state_id: "308" },
  { id: "1319", name: "Sasaram", state_id: "308" },
  { id: "1320", name: "Samastipur", state_id: "308" },

  { id: "1321", name: "Raipur", state_id: "309" },
  { id: "1322", name: "Bilaspur", state_id: "309" },
  { id: "1323", name: "Durg", state_id: "309" },
  { id: "1324", name: "Korba", state_id: "309" },
  { id: "1325", name: "Rajnandgaon", state_id: "309" },
  { id: "1326", name: "Jagdalpur", state_id: "309" },
  { id: "1327", name: "Ambikapur", state_id: "309" },
  { id: "1328", name: "Raigarh", state_id: "309" },
  { id: "1329", name: "Kawardha", state_id: "309" },
  { id: "1330", name: "Mahasamund", state_id: "309" },
  { id: "1331", name: "Dhamtari", state_id: "309" },
  { id: "1332", name: "Kanker", state_id: "309" },

  { id: "1333", name: "North Goa", state_id: "310" },
  { id: "1334", name: "South Goa", state_id: "310" },

  { id: "1335", name: "Ahmedabad", state_id: "311" },
  { id: "1336", name: "Surat", state_id: "311" },
  { id: "1337", name: "Vadodara", state_id: "311" },
  { id: "1338", name: "Rajkot", state_id: "311" },
  { id: "1339", name: "Gandhinagar", state_id: "311" },
  { id: "1340", name: "Junagadh", state_id: "311" },
  { id: "1341", name: "Bhavnagar", state_id: "311" },
  { id: "1342", name: "Kutch", state_id: "311" },
  { id: "1343", name: "Amreli", state_id: "311" },
  { id: "1344", name: "Patan", state_id: "311" },
  { id: "1345", name: "Mahesana", state_id: "311" },
  { id: "1346", name: "Dahod", state_id: "311" },

  { id: "1347", name: "Gurugram", state_id: "312" },
  { id: "1348", name: "Faridabad", state_id: "312" },
  { id: "1349", name: "Ambala", state_id: "312" },
  { id: "1350", name: "Hisar", state_id: "312" },
  { id: "1351", name: "Karnal", state_id: "312" },
  { id: "1352", name: "Panipat", state_id: "312" },
  { id: "1353", name: "Rohtak", state_id: "312" },
  { id: "1354", name: "Sonipat", state_id: "312" },
  { id: "1355", name: "Yamunanagar", state_id: "312" },
  { id: "1356", name: "Kaithal", state_id: "312" },
  { id: "1357", name: "Fatehabad", state_id: "312" },
  { id: "1358", name: "Jhajjar", state_id: "312" },

  { id: "1359", name: "Shimla", state_id: "313" },
  { id: "1360", name: "Mandi", state_id: "313" },
  { id: "1361", name: "Kangra", state_id: "313" },
  { id: "1362", name: "Hamirpur", state_id: "313" },
  { id: "1363", name: "Kullu", state_id: "313" },
  { id: "1364", name: "Sirmaur", state_id: "313" },
  { id: "1365", name: "Bilaspur", state_id: "313" },
  { id: "1366", name: "Chamba", state_id: "313" },
  { id: "1367", name: "Una", state_id: "313" },
  { id: "1368", name: "Solan", state_id: "313" },
  { id: "1369", name: "Lahaul and Spiti", state_id: "313" },
  { id: "1370", name: "Kinnaur", state_id: "313" },

  { id: "1371", name: "Ranchi", state_id: "314" },
  { id: "1373", name: "Dhanbad", state_id: "314" },
  { id: "1372", name: "Jamshedpur", state_id: "314" },
  { id: "1374", name: "Hazaribagh", state_id: "314" },
  { id: "1375", name: "Giridih", state_id: "314" },
  { id: "1376", name: "Deoghar", state_id: "314" },
  { id: "1377", name: "Koderma", state_id: "314" },
  { id: "1378", name: "Chatra", state_id: "314" },
  { id: "1379", name: "Seraikela-Kharsawan", state_id: "314" },
  { id: "1380", name: "Palamu", state_id: "314" },
  { id: "1381", name: "East Singhbhum", state_id: "314" },
  { id: "1382", name: "West Singhbhum", state_id: "314" },

  { id: "1383", name: "Bengaluru Urban", state_id: "315" },
  { id: "1384", name: "Mysuru", state_id: "315" },
  { id: "1385", name: "Bagalkot", state_id: "315" },
  { id: "1386", name: "Belagavi", state_id: "315" },
  { id: "1387", name: "Chikkamagaluru", state_id: "315" },
  { id: "1388", name: "Davanagere", state_id: "315" },
  { id: "1389", name: "Gadag", state_id: "315" },
  { id: "1390", name: "Hassan", state_id: "315" },
  { id: "1391", name: "Hubli-Dharwad", state_id: "315" },
  { id: "1392", name: "Kalaburagi", state_id: "315" },
  { id: "1393", name: "Kolar", state_id: "315" },
  { id: "1394", name: "Mandya", state_id: "315" },
  { id: "1395", name: "Raichur", state_id: "315" },
  { id: "1396", name: "Ramanagara", state_id: "315" },
  { id: "1397", name: "Tumakuru", state_id: "315" },
  { id: "1398", name: "Udupi", state_id: "315" },
  { id: "1399", name: "Vijayapura", state_id: "315" },
  { id: "1400", name: "Yadgir", state_id: "315" },
  { id: "1401", name: "Kodagu", state_id: "315" },
  { id: "1402", name: "Chitradurga", state_id: "315" },

  { id: "1403", name: "Thiruvananthapuram", state_id: "316" },
  { id: "1404", name: "Kollam", state_id: "316" },
  { id: "1405", name: "Pathanamthitta", state_id: "316" },
  { id: "1406", name: "Alappuzha", state_id: "316" },
  { id: "1407", name: "Kottayam", state_id: "316" },
  { id: "1408", name: "Idukki", state_id: "316" },
  { id: "1409", name: "Ernakulam", state_id: "316" },
  { id: "1410", name: "Thrissur", state_id: "316" },
  { id: "1411", name: "Palakkad", state_id: "316" },
  { id: "1412", name: "Malappuram", state_id: "316" },
  { id: "1413", name: "Kozhikode", state_id: "316" },
  { id: "1414", name: "Wayanad", state_id: "316" },
  { id: "1415", name: "Kannur", state_id: "316" },
  { id: "1416", name: "Kasargod", state_id: "316" },

  { id: "1417", name: "Bhopal", state_id: "317" },
  { id: "1418", name: "Indore", state_id: "317" },
  { id: "1419", name: "Gwalior", state_id: "317" },
  { id: "1420", name: "Jabalpur", state_id: "317" },
  { id: "1421", name: "Ujjain", state_id: "317" },
  { id: "1422", name: "Sagar", state_id: "317" },
  { id: "1423", name: "Satna", state_id: "317" },
  { id: "1424", name: "Rewa", state_id: "317" },
  { id: "1425", name: "Dewas", state_id: "317" },
  { id: "1426", name: "Shivpuri", state_id: "317" },
  { id: "1427", name: "Ratlam", state_id: "317" },
  { id: "1428", name: "Chhindwara", state_id: "317" },
  { id: "1429", name: "Mandla", state_id: "317" },
  { id: "1430", name: "Balaghat", state_id: "317" },
  { id: "1431", name: "Mandsaur", state_id: "317" },
  { id: "1432", name: "Bhind", state_id: "317" },
  { id: "1433", name: " Morena", state_id: "317" },
  { id: "1434", name: "Guna", state_id: "317" },

  { id: "1435", name: "Imphal East", state_id: "318" },
  { id: "1436", name: "Imphal West", state_id: "318" },
  { id: "1437", name: "Thoubal", state_id: "318" },
  { id: "1438", name: "Churachandpur", state_id: "318" },
  { id: "1439", name: "Ukhrul", state_id: "318" },
  { id: "1440", name: "Bishnupur", state_id: "318" },
  { id: "1441", name: "Senapati", state_id: "318" },
  { id: "1442", name: "Tamenglong", state_id: "318" },
  { id: "1443", name: "Jiribam", state_id: "318" },
  { id: "1444", name: "Kakching", state_id: "318" },

  { id: "1445", name: "East Khasi Hills", state_id: "319" },
  { id: "1446", name: "West Khasi Hills", state_id: "319" },
  { id: "1447", name: "South West Khasi Hills", state_id: "319" },
  { id: "1448", name: "Jaintia Hills", state_id: "319" },
  { id: "1449", name: "West Jaintia Hills", state_id: "319" },
  { id: "1450", name: "South West Jaintia Hills", state_id: "319" },
  { id: "1451", name: "Ri-Bhoi", state_id: "319" },
  { id: "1452", name: "West Garo Hills", state_id: "319" },
  { id: "1453", name: "South West Garo Hills", state_id: "319" },
  { id: "1454", name: "East Garo Hills", state_id: "319" },
  { id: "1455", name: "North Garo Hills", state_id: "319" },

  { id: "1456", name: "Aizawl", state_id: "320" },
  { id: "1457", name: "Lunglei", state_id: "320" },
  { id: "1458", name: "Mamit", state_id: "320" },
  { id: "1459", name: "Champhai", state_id: "320" },
  { id: "1460", name: "Serchhip", state_id: "320" },
  { id: "1461", name: "Kolasib", state_id: "320" },
  { id: "1462", name: "Lawngtlai", state_id: "320" },
  { id: "1463", name: "Hnahthial", state_id: "320" },
  { id: "1464", name: "Saitual", state_id: "320" },

  { id: "1465", name: "Kohima", state_id: "321" },
  { id: "1466", name: "Dimapur", state_id: "321" },
  { id: "1467", name: "Mokokchung", state_id: "321" },
  { id: "1468", name: "Mon", state_id: "321" },
  { id: "1469", name: "Phek", state_id: "321" },
  { id: "1470", name: "Tuensang", state_id: "321" },
  { id: "1471", name: "Zunheboto", state_id: "321" },
  { id: "1472", name: "Peren", state_id: "321" },
  { id: "1473", name: "Noklak", state_id: "321" },

  { id: "1474", name: "Bhubaneswar", state_id: "322" },
  { id: "1475", name: "Cuttack", state_id: "322" },
  { id: "1476", name: "Ganjam", state_id: "322" },
  { id: "1477", name: "Sambalpur", state_id: "322" },
  { id: "1478", name: "Berhampur", state_id: "322" },
  { id: "1479", name: "Khurda", state_id: "322" },
  { id: "1480", name: "Jajpur", state_id: "322" },
  { id: "1481", name: "Kendrapara", state_id: "322" },
  { id: "1482", name: "Balasore", state_id: "322" },
  { id: "1483", name: "Jagatsinghpur", state_id: "322" },
  { id: "1484", name: "Dhenkanal", state_id: "322" },
  { id: "1485", name: "Malkangiri", state_id: "322" },
  { id: "1486", name: "Nabarangpur", state_id: "322" },
  { id: "1487", name: "Nuapada", state_id: "322" },
  { id: "1488", name: "Koraput", state_id: "322" },
  { id: "1489", name: "Rayagada", state_id: "322" },
  { id: "1490", name: "Gajapati", state_id: "322" },
  { id: "1491", name: "Boudh", state_id: "322" },
  { id: "1492", name: "Subarnapur", state_id: "322" },
  { id: "1493", name: "Deogarh", state_id: "322" },
  { id: "1494", name: "Angul", state_id: "322" },
  { id: "1495", name: "Jhargram", state_id: "322" },

  { id: "1496", name: "Amritsar", state_id: "323" },
  { id: "1497", name: "Jalandhar", state_id: "323" },
  { id: "1498", name: "Ludhiana", state_id: "323" },
  { id: "1499", name: "Patiala", state_id: "323" },
  { id: "1500", name: "Mohali", state_id: "323" },
  { id: "1501", name: "Gurdaspur", state_id: "323" },
  { id: "1502", name: "Fatehgarh Sahib", state_id: "323" },
  { id: "1503", name: "Sangrur", state_id: "323" },
  { id: "1504", name: "Rupnagar", state_id: "323" },
  { id: "1505", name: "Faridkot", state_id: "323" },
  { id: "1506", name: "Mansa", state_id: "323" },
  { id: "1507", name: "Barnala", state_id: "323" },
  { id: "1508", name: "Pathankot", state_id: "323" },
  { id: "1509", name: "Kapurthala", state_id: "323" },
  { id: "1510", name: "Tarn Taran", state_id: "323" },
  { id: "1511", name: "Hoshiarpur", state_id: "323" },
  { id: "1512", name: "Jagraon", state_id: "323" },

  { id: "1513", name: "Jaipur", state_id: "324" },
  { id: "1514", name: "Jodhpur", state_id: "324" },
  { id: "1515", name: "Kota", state_id: "324" },
  { id: "1516", name: "Bikaner", state_id: "324" },
  { id: "1517", name: "Udaipur", state_id: "324" },
  { id: "1518", name: "Ajmer", state_id: "324" },
  { id: "1519", name: "Alwar", state_id: "324" },
  { id: "1520", name: "Sikar", state_id: "324" },
  { id: "1521", name: "Pali", state_id: "324" },
  { id: "1522", name: "Chittorgarh", state_id: "324" },
  { id: "1523", name: "Barmer", state_id: "324" },
  { id: "1524", name: "Dungarpur", state_id: "324" },
  { id: "1525", name: "Jhunjhunu", state_id: "324" },
  { id: "1526", name: "Rajsamand", state_id: "324" },
  { id: "1527", name: "Banswara", state_id: "324" },
  { id: "1528", name: "Sirohi", state_id: "324" },
  { id: "1529", name: "Jaisalmer", state_id: "324" },
  { id: "1530", name: "Tonk", state_id: "324" },
  { id: "1531", name: "Karauli", state_id: "324" },
  { id: "1532", name: "Bundi", state_id: "324" },
  { id: "1533", name: "Sawai Madhopur", state_id: "324" },

  { id: "1534", name: "East Sikkim", state_id: "325" },
  { id: "1535", name: "West Sikkim", state_id: "325" },
  { id: "1536", name: "North Sikkim", state_id: "325" },
  { id: "1537", name: "South Sikkim", state_id: "325" },

  { id: "1538", name: "Hyderabad", state_id: "326" },
  { id: "1539", name: "Ranga Reddy", state_id: "326" },
  { id: "1540", name: "Warangal", state_id: "326" },
  { id: "1541", name: "Khammam", state_id: "326" },
  { id: "1542", name: "Nalgonda", state_id: "326" },
  { id: "1543", name: "Adilabad", state_id: "326" },
  { id: "1544", name: "Medak", state_id: "326" },
  { id: "1545", name: "Mahabubnagar", state_id: "326" },
  { id: "1546", name: "Nizamabad", state_id: "326" },
  { id: "1547", name: "Jagitial", state_id: "326" },
  { id: "1548", name: "Jangaon", state_id: "326" },
  { id: "1549", name: "Kamareddy", state_id: "326" },
  { id: "1550", name: "Kumuram Bheem", state_id: "326" },
  { id: "1551", name: "Nagarkurnool", state_id: "326" },
  { id: "1552", name: "Sangareddy", state_id: "326" },
  { id: "1553", name: "Vikarabad", state_id: "326" },

  { id: "1554", name: "West Tripura", state_id: "327" },
  { id: "1555", name: "Sepahijala", state_id: "327" },
  { id: "1556", name: "Khowai", state_id: "327" },
  { id: "1557", name: "Gomati", state_id: "327" },
  { id: "1558", name: "Dhalai", state_id: "327" },
  { id: "1559", name: "North Tripura", state_id: "327" },
  { id: "1560", name: "Unakoti", state_id: "327" },

  { id: "1561", name: "Dehradun", state_id: "328" },
  { id: "1562", name: "Haridwar", state_id: "328" },
  { id: "1563", name: "Nainital", state_id: "328" },
  { id: "1564", name: "Uttarkashi", state_id: "328" },
  { id: "1565", name: "Pauri Garhwal", state_id: "328" },
  { id: "1566", name: "Champawat", state_id: "328" },
  { id: "1567", name: "Tehri Garhwal", state_id: "328" },
  { id: "1568", name: "Bageshwar", state_id: "328" },
  { id: "1569", name: "Almora", state_id: "328" },
  { id: "1570", name: "Pithoragarh", state_id: "328" },
  { id: "1571", name: "Rudraprayag", state_id: "328" },

  { id: "1572", name: "North and Middle Andaman", state_id: "329" },
  { id: "1573", name: "South Andaman", state_id: "329" },
  { id: "1574", name: "Nicobar", state_id: "329" },
  { id: "1574", name: "Nicobar", state_id: "329" },

  { id: "1575", name: "Chandigarh", state_id: "330" },

  { id: "1576", name: "Dadra and Nagar Haveli", state_id: "331" },
  { id: "1577", name: "Daman", state_id: "331" },
  { id: "1578", name: "Diu", state_id: "331" },

  { id: "1579", name: "Lakshadweep", state_id: "332" },

  { id: "1580", name: "New Delhi", state_id: "333" },
  { id: "1581", name: "North Delhi", state_id: "333" },
  { id: "1582", name: "South Delhi", state_id: "333" },
  { id: "1583", name: "East Delhi", state_id: "333" },
  { id: "1584", name: "West Delhi", state_id: "333" },
  { id: "1585", name: "North East Delhi", state_id: "333" },
  { id: "1586", name: "North West Delhi", state_id: "333" },
  { id: "1587", name: "South East Delhi", state_id: "333" },
  { id: "1588", name: "South West Delhi", state_id: "333" },
  { id: "1589", name: "Central Delhi", state_id: "333" },

  { id: "1590", name: "Puducherry", state_id: "334" },
  { id: "1591", name: "Karaikal", state_id: "334" },
  { id: "1592", name: "Mahe", state_id: "334" },
  { id: "1593", name: "Yanam", state_id: "334" },

  { id: "1594", name: "Srinagar", state_id: "335" },
  { id: "1595", name: "Anantnag", state_id: "335" },
  { id: "1596", name: "Baramulla", state_id: "335" },
  { id: "1597", name: "Pulwama", state_id: "335" },
  { id: "1598", name: "Kupwara", state_id: "335" },
  { id: "1599", name: "Budgam", state_id: "335" },
  { id: "1600", name: "Bandipora", state_id: "335" },
  { id: "1601", name: "Ganderbal", state_id: "335" },
  { id: "1602", name: "Kulgam", state_id: "335" },
  { id: "1603", name: "Shopian", state_id: "335" },
  { id: "1604", name: "Jammu", state_id: "335" },
  { id: "1605", name: "Udhampur", state_id: "335" },
  { id: "1606", name: "Kathua", state_id: "335" },
  { id: "1607", name: "Poonch", state_id: "335" },
  { id: "1608", name: "Rajouri", state_id: "335" },
  { id: "1609", name: "Reasi", state_id: "335" },
  { id: "1610", name: "Ramban", state_id: "335" },
  { id: "1611", name: "Doda", state_id: "335" },
  { id: "1612", name: "Kishtwar", state_id: "335" },
  { id: "1613", name: "Samba", state_id: "335" },

  { id: "1614", name: "Leh", state_id: "336" },
  { id: "1615", name: "Kargil", state_id: "336" },

  { id: "1616", name: "Abra", state_id: "401" },
  { id: "1617", name: "Bangued", state_id: "401" },
  { id: "1618", name: "Boliney", state_id: "401" },
  { id: "1619", name: "Bucay", state_id: "401" },
  { id: "1620", name: "Bucloc", state_id: "401" },
  { id: "1621", name: "Daguioman", state_id: "401" },
  { id: "1622", name: "Danglas", state_id: "401" },
  { id: "1623", name: "Dolores", state_id: "401" },
  { id: "1624", name: "La Paz", state_id: "401" },
  { id: "1625", name: "Lacub", state_id: "401" },
  { id: "1626", name: "Lagangilang", state_id: "401" },
  { id: "1627", name: "Lagayan", state_id: "401" },
  { id: "1628", name: "Langiden", state_id: "401" },
  { id: "1629", name: "Licuan-Baay", state_id: "401" },
  { id: "1630", name: "Luba", state_id: "401" },
  { id: "1631", name: "Malibcong", state_id: "401" },
  { id: "1632", name: "Manabo", state_id: "401" },
  { id: "1633", name: "Peñarrubia", state_id: "401" },
  { id: "1634", name: "Pidigan", state_id: "401" },
  { id: "1635", name: "Pilar", state_id: "401" },
  { id: "1636", name: "Sallapadan", state_id: "401" },
  { id: "1637", name: "San Isidro", state_id: "401" },
  { id: "1638", name: "San Juan", state_id: "401" },
  { id: "1639", name: "San Quintin", state_id: "401" },
  { id: "1640", name: "Tayum", state_id: "401" },
  { id: "1641", name: "Tineg", state_id: "401" },
  { id: "1642", name: "Tubo", state_id: "401" },
  { id: "1643", name: "Villaviciosa", state_id: "401" },

  { id: "1644", name: "Albay", state_id: "402" },
  { id: "1645", name: "Bacacay", state_id: "402" },
  { id: "1646", name: "Camalig", state_id: "402" },
  { id: "1647", name: "Daraga", state_id: "402" },
  { id: "1648", name: "Guinobatan", state_id: "402" },
  { id: "1649", name: "Jovellar", state_id: "402" },
  { id: "1650", name: "Legazpi City", state_id: "402" },
  { id: "1651", name: "Libon", state_id: "402" },
  { id: "1652", name: "Ligao", state_id: "402" },
  { id: "1653", name: "Malilipot", state_id: "402" },
  { id: "1654", name: "Malinao", state_id: "402" },
  { id: "1655", name: "Manito", state_id: "402" },
  { id: "1656", name: "Oas", state_id: "402" },
  { id: "1657", name: "Pio Duran", state_id: "402" },
  { id: "1658", name: "Polangui", state_id: "402" },
  { id: "1659", name: "Rapu-Rapu", state_id: "402" },
  { id: "1660", name: "Santo Domingo", state_id: "402" },
  { id: "1661", name: "Tabaco", state_id: "402" },
  { id: "1662", name: "Tiwi", state_id: "402" },

  { id: "1663", name: "Apayao", state_id: "403" },
  { id: "1664", name: "Calanasan", state_id: "403" },
  { id: "1665", name: "Conner", state_id: "403" },
  { id: "1666", name: "Flora", state_id: "403" },
  { id: "1667", name: "Kabugao", state_id: "403" },
  { id: "1668", name: "Luna", state_id: "403" },
  { id: "1669", name: "Pudtol", state_id: "403" },
  { id: "1670", name: "Santa Marcela", state_id: "403" },

  { id: "1671", name: "Aurora", state_id: "404" },
  { id: "1672", name: "Baler", state_id: "404" },
  { id: "1673", name: "Casiguran", state_id: "404" },
  { id: "1674", name: "Dilasag", state_id: "404" },
  { id: "1675", name: "Dinalungan", state_id: "404" },
  { id: "1676", name: "Dingalan", state_id: "404" },
  { id: "1677", name: "Dipaculao", state_id: "404" },
  { id: "1678", name: "Maria Aurora", state_id: "404" },
  { id: "1679", name: "San Luis", state_id: "404" },

  { id: "1680", name: "Bataan", state_id: "405" },
  { id: "1681", name: "Abucay", state_id: "405" },
  { id: "1682", name: "Bagac", state_id: "405" },
  { id: "1683", name: "Balanga", state_id: "405" },
  { id: "1684", name: "Dinalupihan", state_id: "405" },
  { id: "1685", name: "Hermosa", state_id: "405" },
  { id: "1686", name: "Limay", state_id: "405" },
  { id: "1687", name: "Mariveles", state_id: "405" },
  { id: "1688", name: "Morong", state_id: "405" },
  { id: "1689", name: "Orani", state_id: "405" },
  { id: "1690", name: "Orion", state_id: "405" },
  { id: "1691", name: "Pilar", state_id: "405" },
  { id: "1692", name: "Samal", state_id: "405" },

  { id: "1693", name: "Batanes", state_id: "406" },
  { id: "1694", name: "Basco", state_id: "406" },
  { id: "1695", name: "Itbayat", state_id: "406" },
  { id: "1696", name: "Ivana", state_id: "406" },
  { id: "1697", name: "Mahatao", state_id: "406" },
  { id: "1698", name: "Sabtang", state_id: "406" },
  { id: "1699", name: "Uyugan", state_id: "406" },

  { id: "1700", name: "Batangas", state_id: "407" },
  { id: "1701", name: "Agoncillo", state_id: "407" },
  { id: "1702", name: "Alitagtag", state_id: "407" },
  { id: "1703", name: "Balayan", state_id: "407" },
  { id: "1704", name: "Balete", state_id: "407" },
  { id: "1705", name: "Batangas City", state_id: "407" },
  { id: "1706", name: "Bauan", state_id: "407" },
  { id: "1707", name: "Calaca", state_id: "407" },
  { id: "1708", name: "Calatagan", state_id: "407" },
  { id: "1709", name: "Cuenca", state_id: "407" },
  { id: "1710", name: "Ibaan", state_id: "407" },
  { id: "1711", name: "Laurel", state_id: "407" },
  { id: "1712", name: "Lemery", state_id: "407" },
  { id: "1713", name: "Lian", state_id: "407" },
  { id: "1714", name: "Lipa", state_id: "407" },
  { id: "1715", name: "Lobo", state_id: "407" },
  { id: "1716", name: "Mabini", state_id: "407" },
  { id: "1717", name: "Malvar", state_id: "407" },
  { id: "1718", name: "Mataasnakahoy", state_id: "407" },
  { id: "1719", name: "Nasugbu", state_id: "407" },
  { id: "1720", name: "Padre Garcia", state_id: "407" },
  { id: "1721", name: "Rosario", state_id: "407" },
  { id: "1722", name: "San Jose", state_id: "407" },
  { id: "1723", name: "San Juan", state_id: "407" },
  { id: "1724", name: "San Luis", state_id: "407" },
  { id: "1725", name: "San Nicolas", state_id: "407" },
  { id: "1726", name: "San Pascual", state_id: "407" },
  { id: "1727", name: "Santa Teresita", state_id: "407" },
  { id: "1728", name: "Santo Tomas", state_id: "407" },
  { id: "1729", name: "Taal", state_id: "407" },
  { id: "1730", name: "Talisay", state_id: "407" },
  { id: "1731", name: "Tanauan", state_id: "407" },
  { id: "1732", name: "Taysan", state_id: "407" },
  { id: "1733", name: "Tingloy", state_id: "407" },
  { id: "1734", name: "Tuy", state_id: "407" },

  { id: "1735", name: "Benguet", state_id: "408" },
  { id: "1736", name: "Atok", state_id: "408" },
  { id: "1737", name: "Bakun", state_id: "408" },
  { id: "1738", name: "Bokod", state_id: "408" },
  { id: "1739", name: "Buguias", state_id: "408" },
  { id: "1740", name: "Itogon", state_id: "408" },
  { id: "1741", name: "Kabayan", state_id: "408" },
  { id: "1742", name: "Kapangan", state_id: "408" },
  { id: "1743", name: "Kibungan", state_id: "408" },
  { id: "1744", name: "La Trinidad", state_id: "408" },
  { id: "1745", name: "Mankayan", state_id: "408" },
  { id: "1746", name: "Sablan", state_id: "408" },
  { id: "1747", name: "Tuba", state_id: "408" },
  { id: "1748", name: "Tublay", state_id: "408" },

  { id: "1749", name: "Bulacan", state_id: "409" },
  { id: "1750", name: "Angat", state_id: "409" },
  { id: "1751", name: "Balagtas", state_id: "409" },
  { id: "1752", name: "Baliuag", state_id: "409" },
  { id: "1753", name: "Bocaue", state_id: "409" },
  { id: "1754", name: "Bulacan City", state_id: "409" },
  { id: "1755", name: "Bustos", state_id: "409" },
  { id: "1756", name: "Calumpit", state_id: "409" },
  { id: "1757", name: "Doña Remedios Trinidad", state_id: "409" },
  { id: "1758", name: "Guiguinto", state_id: "409" },
  { id: "1759", name: "Hagonoy", state_id: "409" },
  { id: "1760", name: "Malolos", state_id: "409" },
  { id: "1761", name: "Marilao", state_id: "409" },
  { id: "1762", name: "Meycauayan", state_id: "409" },
  { id: "1763", name: "Norzagaray", state_id: "409" },
  { id: "1764", name: "Obando", state_id: "409" },
  { id: "1765", name: "Pandi", state_id: "409" },
  { id: "1766", name: "Paombong", state_id: "409" },
  { id: "1767", name: "Plaridel", state_id: "409" },
  { id: "1768", name: "Pulilan", state_id: "409" },
  { id: "1769", name: "San Ildefonso", state_id: "409" },
  { id: "1770", name: "San Miguel", state_id: "409" },
  { id: "1771", name: "San Rafael", state_id: "409" },
  { id: "1772", name: "Santa Maria", state_id: "409" },

  { id: "1773", name: "Cagayan", state_id: "410" },
  { id: "1774", name: "Abulug", state_id: "410" },
  { id: "1775", name: "Alcala", state_id: "410" },
  { id: "1776", name: "Allacapan", state_id: "410" },
  { id: "1777", name: "Amulung", state_id: "410" },
  { id: "1778", name: "Aparri", state_id: "410" },
  { id: "1779", name: "Baggao", state_id: "410" },
  { id: "1780", name: "Ballesteros", state_id: "410" },
  { id: "1781", name: "Buguey", state_id: "410" },
  { id: "1782", name: "Calayan", state_id: "410" },
  { id: "1783", name: "Camalaniugan", state_id: "410" },
  { id: "1784", name: "Claveria", state_id: "410" },
  { id: "1785", name: "Enrile", state_id: "410" },
  { id: "1786", name: "Gattaran", state_id: "410" },
  { id: "1787", name: "Gonzaga", state_id: "410" },
  { id: "1788", name: "Iguig", state_id: "410" },
  { id: "1789", name: "Lal-lo", state_id: "410" },
  { id: "1790", name: "Lasam", state_id: "410" },
  { id: "1791", name: "Pamplona", state_id: "410" },
  { id: "1792", name: "Peñablanca", state_id: "410" },
  { id: "1793", name: "Piat", state_id: "410" },
  { id: "1794", name: "Rizal", state_id: "410" },
  { id: "1795", name: "Sanchez-Mira", state_id: "410" },
  { id: "1796", name: "Santa Ana", state_id: "410" },
  { id: "1797", name: "Santa Praxedes", state_id: "410" },
  { id: "1798", name: "Santa Teresita", state_id: "410" },
  { id: "1799", name: "Santo Niño", state_id: "410" },
  { id: "1800", name: "Solana", state_id: "410" },
  { id: "1801", name: "Tuao", state_id: "410" },
  { id: "1802", name: "Tuguegarao", state_id: "410" },

  { id: "1803", name: "Camarines Norte", state_id: "411" },
  { id: "1804", name: "Basud", state_id: "411" },
  { id: "1805", name: "Capalonga", state_id: "411" },
  { id: "1806", name: "Daet", state_id: "411" },
  { id: "1807", name: "Jose Panganiban", state_id: "411" },
  { id: "1808", name: "Labo", state_id: "411" },
  { id: "1809", name: "Mercedes", state_id: "411" },
  { id: "1810", name: "Paracale", state_id: "411" },
  { id: "1811", name: "San Lorenzo Ruiz", state_id: "411" },
  { id: "1812", name: "San Vicente", state_id: "411" },
  { id: "1813", name: "Santa Elena", state_id: "411" },
  { id: "1814", name: "Talisay", state_id: "411" },
  { id: "1815", name: "Vinzons", state_id: "411" },

  { id: "1816", name: "Camarines Sur", state_id: "412" },
  { id: "1817", name: "Baao", state_id: "412" },
  { id: "1818", name: "Balatan", state_id: "412" },
  { id: "1819", name: "Bato", state_id: "412" },
  { id: "1820", name: "Bombon", state_id: "412" },
  { id: "1821", name: "Buhi", state_id: "412" },
  { id: "1822", name: "Bula", state_id: "412" },
  { id: "1823", name: "Cabusao", state_id: "412" },
  { id: "1824", name: "Calabanga", state_id: "412" },
  { id: "1825", name: "Camaligan", state_id: "412" },
  { id: "1826", name: "Canaman", state_id: "412" },
  { id: "1827", name: "Caramoan", state_id: "412" },
  { id: "1828", name: "Del Gallego", state_id: "412" },
  { id: "1829", name: "Gainza", state_id: "412" },
  { id: "1830", name: "Garchitorena", state_id: "412" },
  { id: "1831", name: "Goa", state_id: "412" },
  { id: "1832", name: "Iriga", state_id: "412" },
  { id: "1833", name: "Lagonoy", state_id: "412" },
  { id: "1834", name: "Libmanan", state_id: "412" },
  { id: "1835", name: "Lupi", state_id: "412" },
  { id: "1836", name: "Magarao", state_id: "412" },
  { id: "1837", name: "Milaor", state_id: "412" },
  { id: "1838", name: "Minalabac", state_id: "412" },
  { id: "1839", name: "Nabua", state_id: "412" },
  { id: "1840", name: "Naga", state_id: "412" },
  { id: "1841", name: "Ocampo", state_id: "412" },
  { id: "1842", name: "Pamplona", state_id: "412" },
  { id: "1843", name: "Pasacao", state_id: "412" },
  { id: "1844", name: "Pili", state_id: "412" },
  { id: "1845", name: "Presentacion", state_id: "412" },
  { id: "1846", name: "Ragay", state_id: "412" },
  { id: "1847", name: "Sagñay", state_id: "412" },
  { id: "1848", name: "San Fernando", state_id: "412" },
  { id: "1849", name: "San Jose", state_id: "412" },
  { id: "1850", name: "Sipocot", state_id: "412" },
  { id: "1851", name: "Siruma", state_id: "412" },
  { id: "1852", name: "Tigaon", state_id: "412" },
  { id: "1853", name: "Tinambac", state_id: "412" },

  { id: "1854", name: "Catanduanes", state_id: "413" },
  { id: "1855", name: "Bagamanoc", state_id: "413" },
  { id: "1856", name: "Baras", state_id: "413" },
  { id: "1857", name: "Bato", state_id: "413" },
  { id: "1858", name: "Caramoran", state_id: "413" },
  { id: "1859", name: "Gigmoto", state_id: "413" },
  { id: "1860", name: "Pandan", state_id: "413" },
  { id: "1861", name: "Panganiban", state_id: "413" },
  { id: "1862", name: "San Andres", state_id: "413" },
  { id: "1863", name: "San Miguel", state_id: "413" },
  { id: "1864", name: "Viga", state_id: "413" },
  { id: "1865", name: "Virac", state_id: "413" },

  { id: "1866", name: "Cavite", state_id: "414" },
  { id: "1867", name: "Alfonso", state_id: "414" },
  { id: "1868", name: "Amadeo", state_id: "414" },
  { id: "1869", name: "Bacoor", state_id: "414" },
  { id: "1870", name: "Carmona", state_id: "414" },
  { id: "1871", name: "Cavite City", state_id: "414" },
  { id: "1872", name: "Dasmariñas", state_id: "414" },
  { id: "1873", name: "General Emilio Aguinaldo", state_id: "414" },
  { id: "1874", name: "General Mariano Alvarez", state_id: "414" },
  { id: "1875", name: "General Trias", state_id: "414" },
  { id: "1876", name: "Imus", state_id: "414" },
  { id: "1877", name: "Indang", state_id: "414" },
  { id: "1878", name: "Kawit", state_id: "414" },
  { id: "1879", name: "Magallanes", state_id: "414" },
  { id: "1880", name: "Maragondon", state_id: "414" },
  { id: "1881", name: "Mendez", state_id: "414" },
  { id: "1882", name: "Naic", state_id: "414" },
  { id: "1883", name: "Noveleta", state_id: "414" },
  { id: "1884", name: "Rosario", state_id: "414" },
  { id: "1885", name: "Silang", state_id: "414" },
  { id: "1886", name: "Tagaytay", state_id: "414" },
  { id: "1887", name: "Tanza", state_id: "414" },
  { id: "1888", name: "Ternate", state_id: "414" },
  { id: "1889", name: "Trece Martires", state_id: "414" },

  { id: "1890", name: "Ifugao", state_id: "415" },
  { id: "1891", name: "Aguinaldo", state_id: "415" },
  { id: "1892", name: "Alfonso Lista", state_id: "415" },
  { id: "1893", name: "Asipulo", state_id: "415" },
  { id: "1894", name: "Banaue", state_id: "415" },
  { id: "1895", name: "Hingyon", state_id: "415" },
  { id: "1896", name: "Hungduan", state_id: "415" },
  { id: "1897", name: "Kiangan", state_id: "415" },
  { id: "1898", name: "Lagawe", state_id: "415" },
  { id: "1899", name: "Lamut", state_id: "415" },
  { id: "1900", name: "Mayoyao", state_id: "415" },
  { id: "1901", name: "Tinoc", state_id: "415" },

  { id: "1902", name: "Ilocos Norte", state_id: "416" },
  { id: "1903", name: "Adams", state_id: "416" },
  { id: "1904", name: "Bacarra", state_id: "416" },
  { id: "1905", name: "Badoc", state_id: "416" },
  { id: "1906", name: "Bangui", state_id: "416" },
  { id: "1907", name: "Banna", state_id: "416" },
  { id: "1908", name: "Batac", state_id: "416" },
  { id: "1909", name: "Burgos", state_id: "416" },
  { id: "1910", name: "Carasi", state_id: "416" },
  { id: "1911", name: "Currimao", state_id: "416" },
  { id: "1912", name: "Dingras", state_id: "416" },
  { id: "1913", name: "Dumalneg", state_id: "416" },
  { id: "1914", name: "Laoag", state_id: "416" },
  { id: "1915", name: "Marcos", state_id: "416" },
  { id: "1916", name: "Nueva Era", state_id: "416" },
  { id: "1917", name: "Pagudpud", state_id: "416" },
  { id: "1918", name: "Paoay", state_id: "416" },
  { id: "1919", name: "Pasuquin", state_id: "416" },
  { id: "1920", name: "Piddig", state_id: "416" },
  { id: "1921", name: "Pinili", state_id: "416" },
  { id: "1922", name: "San Nicolas", state_id: "416" },
  { id: "1923", name: "Sarrat", state_id: "416" },
  { id: "1924", name: "Solsona", state_id: "416" },
  { id: "1925", name: "Vintar", state_id: "416" },

  { id: "1926", name: "Ilocos Sur", state_id: "417" },
  { id: "1927", name: "Alilem", state_id: "417" },
  { id: "1928", name: "Banayoyo", state_id: "417" },
  { id: "1929", name: "Bantay", state_id: "417" },
  { id: "1930", name: "Burgos", state_id: "417" },
  { id: "1931", name: "Cabugao", state_id: "417" },
  { id: "1932", name: "Candon", state_id: "417" },
  { id: "1933", name: "Caoayan", state_id: "417" },
  { id: "1934", name: "Cervantes", state_id: "417" },
  { id: "1935", name: "Galimuyod", state_id: "417" },
  { id: "1936", name: "Gregorio Del Pilar", state_id: "417" },
  { id: "1937", name: "Lidlidda", state_id: "417" },
  { id: "1938", name: "Magsingal", state_id: "417" },
  { id: "1939", name: "Nagbukel", state_id: "417" },
  { id: "1940", name: "Narvacan", state_id: "417" },
  { id: "1941", name: "Quirino", state_id: "417" },
  { id: "1942", name: "Salcedo", state_id: "417" },
  { id: "1943", name: "San Emilio", state_id: "417" },
  { id: "1944", name: "San Esteban", state_id: "417" },
  { id: "1945", name: "San Ildefonso", state_id: "417" },
  { id: "1946", name: "San Juan", state_id: "417" },
  { id: "1947", name: "San Vicente", state_id: "417" },
  { id: "1948", name: "Santa", state_id: "417" },
  { id: "1949", name: "Santa Catalina", state_id: "417" },
  { id: "1950", name: "Santa Cruz", state_id: "417" },
  { id: "1951", name: "Santa Lucia", state_id: "417" },
  { id: "1952", name: "Santa Maria", state_id: "417" },
  { id: "1953", name: "Santiago", state_id: "417" },
  { id: "1954", name: "Santo Domingo", state_id: "417" },
  { id: "1955", name: "Sigay", state_id: "417" },
  { id: "1956", name: "Sinait", state_id: "417" },
  { id: "1957", name: "Sugpon", state_id: "417" },
  { id: "1958", name: "Suyo", state_id: "417" },
  { id: "1959", name: "Tagudin", state_id: "417" },
  { id: "1960", name: "Vigan", state_id: "417" },

  { id: "1961", name: "Isabela", state_id: "418" },
  { id: "1962", name: "Alicia", state_id: "418" },
  { id: "1963", name: "Angadanan", state_id: "418" },
  { id: "1964", name: "Aurora", state_id: "418" },
  { id: "1965", name: "Benito Soliven", state_id: "418" },
  { id: "1966", name: "Burgos", state_id: "418" },
  { id: "1967", name: "Cabagan", state_id: "418" },
  { id: "1968", name: "Cabatuan", state_id: "418" },
  { id: "1969", name: "Cauayan", state_id: "418" },
  { id: "1970", name: "Cordon", state_id: "418" },
  { id: "1971", name: "Delfin Albano", state_id: "418" },
  { id: "1972", name: "Dinapigue", state_id: "418" },
  { id: "1973", name: "Divilacan", state_id: "418" },
  { id: "1974", name: "Echague", state_id: "418" },
  { id: "1975", name: "Gamu", state_id: "418" },
  { id: "1976", name: "Ilagan", state_id: "418" },
  { id: "1977", name: "Jones", state_id: "418" },
  { id: "1978", name: "Luna", state_id: "418" },
  { id: "1979", name: "Maconacon", state_id: "418" },
  { id: "1980", name: "Mallig", state_id: "418" },
  { id: "1981", name: "Naguilian", state_id: "418" },
  { id: "1982", name: "Palanan", state_id: "418" },
  { id: "1983", name: "Quezon", state_id: "418" },
  { id: "1984", name: "Quirino", state_id: "418" },
  { id: "1985", name: "Ramon", state_id: "418" },
  { id: "1986", name: "Reina Mercedes", state_id: "418" },
  { id: "1987", name: "Roxas", state_id: "418" },
  { id: "1988", name: "San Agustin", state_id: "418" },
  { id: "1989", name: "San Guillermo", state_id: "418" },
  { id: "1990", name: "San Isidro", state_id: "418" },
  { id: "1991", name: "San Manuel", state_id: "418" },
  { id: "1992", name: "San Mariano", state_id: "418" },
  { id: "1993", name: "San Mateo", state_id: "418" },
  { id: "1994", name: "San Pablo", state_id: "418" },
  { id: "1995", name: "Santa Maria", state_id: "418" },
  { id: "1996", name: "Santiago", state_id: "418" },
  { id: "1997", name: "Tumauini", state_id: "418" },

  { id: "1998", name: "Balbalan", state_id: "419" },
  { id: "1999", name: "Lubuagan", state_id: "419" },
  { id: "2000", name: "Pasil", state_id: "419" },
  { id: "2001", name: "Pinukpuk", state_id: "419" },
  { id: "2002", name: "Rizal", state_id: "419" },
  { id: "2003", name: "Tabuk", state_id: "419" },
  { id: "2004", name: "Tanudan", state_id: "419" },
  { id: "2005", name: "Tinglayan", state_id: "419" },

  { id: "2006", name: "Agoo", state_id: "420" },
  { id: "2007", name: "Aringay", state_id: "420" },
  { id: "2008", name: "Bacnotan", state_id: "420" },
  { id: "2009", name: "Bagulin", state_id: "420" },
  { id: "2010", name: "Balaoan", state_id: "420" },
  { id: "2011", name: "Bangar", state_id: "420" },
  { id: "2012", name: "Bauang", state_id: "420" },
  { id: "2013", name: "Burgos", state_id: "420" },
  { id: "2014", name: "Caba", state_id: "420" },
  { id: "2015", name: "Luna", state_id: "420" },
  { id: "2016", name: "Naguilian", state_id: "420" },
  { id: "2017", name: "Pugo", state_id: "420" },
  { id: "2018", name: "Rosario", state_id: "420" },
  { id: "2019", name: "San Fernando", state_id: "420" },
  { id: "2020", name: "San Gabriel", state_id: "420" },
  { id: "2021", name: "San Juan", state_id: "420" },
  { id: "2022", name: "Santo Tomas", state_id: "420" },
  { id: "2023", name: "Santol", state_id: "420" },
  { id: "2024", name: "Sudipen", state_id: "420" },
  { id: "2025", name: "Tubao", state_id: "420" },

  { id: "2026", name: "Alaminos", state_id: "421" },
  { id: "2027", name: "Bay", state_id: "421" },
  { id: "2028", name: "Biñan", state_id: "421" },
  { id: "2029", name: "Cabuyao", state_id: "421" },
  { id: "2030", name: "Calamba", state_id: "421" },
  { id: "2031", name: "Calauan", state_id: "421" },
  { id: "2032", name: "Cavinti", state_id: "421" },
  { id: "2033", name: "Famy", state_id: "421" },
  { id: "2034", name: "Kalayaan", state_id: "421" },
  { id: "2035", name: "Liliw", state_id: "421" },
  { id: "2036", name: "Los Baños", state_id: "421" },
  { id: "2037", name: "Luisiana", state_id: "421" },
  { id: "2038", name: "Lumban", state_id: "421" },
  { id: "2039", name: "Mabitac", state_id: "421" },
  { id: "2040", name: "Magdalena", state_id: "421" },
  { id: "2041", name: "Majayjay", state_id: "421" },
  { id: "2042", name: "Nagcarlan", state_id: "421" },
  { id: "2043", name: "Paete", state_id: "421" },
  { id: "2044", name: "Pagsanjan", state_id: "421" },
  { id: "2045", name: "Pakil", state_id: "421" },
  { id: "2046", name: "Pangil", state_id: "421" },
  { id: "2047", name: "Pila", state_id: "421" },
  { id: "2048", name: "Rizal", state_id: "421" },
  { id: "2049", name: "San Pablo", state_id: "421" },
  { id: "2050", name: "San Pedro", state_id: "421" },
  { id: "2051", name: "Santa Cruz", state_id: "421" },
  { id: "2052", name: "Santa Maria", state_id: "421" },
  { id: "2053", name: "Siniloan", state_id: "421" },
  { id: "2054", name: "Victoria", state_id: "421" },

  { id: "2055", name: "Boac", state_id: "422" },
  { id: "2056", name: "Buenavista", state_id: "422" },
  { id: "2057", name: "Gasan", state_id: "422" },
  { id: "2058", name: "Mogpog", state_id: "422" },
  { id: "2059", name: "Santa Cruz", state_id: "422" },
  { id: "2060", name: "Torrijos", state_id: "422" },

  { id: "2061", name: "Aroroy", state_id: "423" },
  { id: "2062", name: "Baleno", state_id: "423" },
  { id: "2063", name: "Balud", state_id: "423" },
  { id: "2064", name: "Batuan", state_id: "423" },
  { id: "2065", name: "Cataingan", state_id: "423" },
  { id: "2066", name: "Cawayan", state_id: "423" },
  { id: "2067", name: "Claveria", state_id: "423" },
  { id: "2068", name: "Dimasalang", state_id: "423" },
  { id: "2069", name: "Esperanza", state_id: "423" },
  { id: "2070", name: "Mandaon", state_id: "423" },
  { id: "2071", name: "Masbate City", state_id: "423" },
  { id: "2072", name: "Milagros", state_id: "423" },
  { id: "2073", name: "Mobo", state_id: "423" },
  { id: "2074", name: "Monreal", state_id: "423" },
  { id: "2075", name: "Palanas", state_id: "423" },
  { id: "2076", name: "Pio V. Corpuz", state_id: "423" },
  { id: "2077", name: "Placer", state_id: "423" },
  { id: "2078", name: "San Fernando", state_id: "423" },
  { id: "2079", name: "San Jacinto", state_id: "423" },
  { id: "2080", name: "San Pascual", state_id: "423" },
  { id: "2081", name: "Uson", state_id: "423" },

  { id: "2082", name: "Barlig", state_id: "424" },
  { id: "2083", name: "Bauko", state_id: "424" },
  { id: "2084", name: "Besao", state_id: "424" },
  { id: "2085", name: "Bontoc", state_id: "424" },
  { id: "2086", name: "Natonin", state_id: "424" },
  { id: "2087", name: "Paracelis", state_id: "424" },
  { id: "2088", name: "Sabangan", state_id: "424" },
  { id: "2089", name: "Sadanga", state_id: "424" },
  { id: "2090", name: "Sagada", state_id: "424" },
  { id: "2091", name: "Tadian", state_id: "424" },

  { id: "2092", name: "Aliaga", state_id: "425" },
  { id: "2093", name: "Bongabon", state_id: "425" },
  { id: "2094", name: "Cabanatuan", state_id: "425" },
  { id: "2095", name: "Cabiao", state_id: "425" },
  { id: "2096", name: "Carranglan", state_id: "425" },
  { id: "2097", name: "Cuyapo", state_id: "425" },
  { id: "2098", name: "Gabaldon", state_id: "425" },
  { id: "2099", name: "Gapan", state_id: "425" },
  { id: "2100", name: "General Mamerto Natividad", state_id: "425" },
  { id: "2101", name: "General Tinio", state_id: "425" },
  { id: "2102", name: "Guimba", state_id: "425" },
  { id: "2103", name: "Jaen", state_id: "425" },
  { id: "2104", name: "Laur", state_id: "425" },
  { id: "2105", name: "Licab", state_id: "425" },
  { id: "2106", name: "Llanera", state_id: "425" },
  { id: "2107", name: "Lupao", state_id: "425" },
  { id: "2108", name: "Muñoz", state_id: "425" },
  { id: "2109", name: "Nampicuan", state_id: "425" },
  { id: "2110", name: "Palayan", state_id: "425" },
  { id: "2111", name: "Pantabangan", state_id: "425" },
  { id: "2112", name: "Peñaranda", state_id: "425" },
  { id: "2113", name: "Quezon", state_id: "425" },
  { id: "2114", name: "Rizal", state_id: "425" },
  { id: "2115", name: "San Antonio", state_id: "425" },
  { id: "2116", name: "San Isidro", state_id: "425" },
  { id: "2117", name: "San Jose", state_id: "425" },
  { id: "2118", name: "San Leonardo", state_id: "425" },
  { id: "2119", name: "Santa Rosa", state_id: "425" },
  { id: "2120", name: "Santo Domingo", state_id: "425" },
  { id: "2121", name: "Talavera", state_id: "425" },
  { id: "2122", name: "Talugtug", state_id: "425" },
  { id: "2123", name: "Zaragoza", state_id: "425" },

  { id: "2124", name: "Alfonso Castañeda", state_id: "426" },
  { id: "2125", name: "Ambaguio", state_id: "426" },
  { id: "2126", name: "Aritao", state_id: "426" },
  { id: "2127", name: "Bagabag", state_id: "426" },
  { id: "2128", name: "Bambang", state_id: "426" },
  { id: "2129", name: "Bayombong", state_id: "426" },
  { id: "2130", name: "Diadi", state_id: "426" },
  { id: "2131", name: "Dupax del Norte", state_id: "426" },
  { id: "2132", name: "Dupax del Sur", state_id: "426" },
  { id: "2133", name: "Kasibu", state_id: "426" },
  { id: "2134", name: "Kayapa", state_id: "426" },
  { id: "2135", name: "Quezon", state_id: "426" },
  { id: "2136", name: "Santa Fe", state_id: "426" },
  { id: "2137", name: "Solano", state_id: "426" },
  { id: "2138", name: "Villaverde", state_id: "426" },

  { id: "2139", name: "Abra de Ilog", state_id: "427" },
  { id: "2140", name: "Calintaan", state_id: "427" },
  { id: "2141", name: "Looc", state_id: "427" },
  { id: "2142", name: "Lubang", state_id: "427" },
  { id: "2143", name: "Magsaysay", state_id: "427" },
  { id: "2144", name: "Mamburao", state_id: "427" },
  { id: "2145", name: "Paluan", state_id: "427" },
  { id: "2146", name: "Rizal", state_id: "427" },
  { id: "2147", name: "Sablayan", state_id: "427" },
  { id: "2148", name: "San Jose", state_id: "427" },

  { id: "2149", name: "Baco", state_id: "428" },
  { id: "2150", name: "Bansud", state_id: "428" },
  { id: "2151", name: "Bongabong", state_id: "428" },
  { id: "2152", name: "Bulalacao", state_id: "428" },
  { id: "2153", name: "Calapan", state_id: "428" },
  { id: "2154", name: "Gloria", state_id: "428" },
  { id: "2155", name: "Mansalay", state_id: "428" },
  { id: "2156", name: "Naujan", state_id: "428" },
  { id: "2157", name: "Pinamalayan", state_id: "428" },
  { id: "2158", name: "Pola", state_id: "428" },
  { id: "2159", name: "Puerto Galera", state_id: "428" },
  { id: "2160", name: "Roxas", state_id: "428" },
  { id: "2161", name: "San Teodoro", state_id: "428" },
  { id: "2162", name: "Socorro", state_id: "428" },
  { id: "2163", name: "Victoria", state_id: "428" },

  { id: "2164", name: "Aborlan", state_id: "429" },
  { id: "2165", name: "Agutaya", state_id: "429" },
  { id: "2166", name: "Araceli", state_id: "429" },
  { id: "2167", name: "Balabac", state_id: "429" },
  { id: "2168", name: "Bataraza", state_id: "429" },
  { id: "2169", name: "Brooke's Point", state_id: "429" },
  { id: "2170", name: "Busuanga", state_id: "429" },
  { id: "2171", name: "Cagayancillo", state_id: "429" },
  { id: "2172", name: "Coron", state_id: "429" },
  { id: "2173", name: "Culion", state_id: "429" },
  { id: "2174", name: "Cuyo", state_id: "429" },
  { id: "2175", name: "Dumaran", state_id: "429" },
  { id: "2176", name: "El Nido", state_id: "429" },
  { id: "2177", name: "Kalayaan", state_id: "429" },
  { id: "2178", name: "Linapacan", state_id: "429" },
  { id: "2179", name: "Magsaysay", state_id: "429" },
  { id: "2180", name: "Narra", state_id: "429" },
  { id: "2181", name: "Puerto Princesa", state_id: "429" },
  { id: "2182", name: "Quezon", state_id: "429" },
  { id: "2183", name: "Rizal", state_id: "429" },
  { id: "2184", name: "Roxas", state_id: "429" },
  { id: "2185", name: "San Vicente", state_id: "429" },
  { id: "2186", name: "Sofronio Española", state_id: "429" },
  { id: "2187", name: "Taytay", state_id: "429" },

  { id: "2188", name: "Angeles City", state_id: "430" },
  { id: "2189", name: "Apalit", state_id: "430" },
  { id: "2190", name: "Arayat", state_id: "430" },
  { id: "2191", name: "Bacolor", state_id: "430" },
  { id: "2192", name: "Candaba", state_id: "430" },
  { id: "2193", name: "Floridablanca", state_id: "430" },
  { id: "2194", name: "Guagua", state_id: "430" },
  { id: "2195", name: "Lubao", state_id: "430" },
  { id: "2196", name: "Mabalacat", state_id: "430" },
  { id: "2197", name: "Macabebe", state_id: "430" },
  { id: "2198", name: "Magalang", state_id: "430" },
  { id: "2199", name: "Masantol", state_id: "430" },
  { id: "2200", name: "Mexico", state_id: "430" },
  { id: "2201", name: "Minalin", state_id: "430" },
  { id: "2202", name: "Porac", state_id: "430" },
  { id: "2203", name: "San Fernando", state_id: "430" },
  { id: "2204", name: "San Luis", state_id: "430" },
  { id: "2205", name: "San Simon", state_id: "430" },
  { id: "2206", name: "Santa Ana", state_id: "430" },
  { id: "2207", name: "Santa Rita", state_id: "430" },
  { id: "2208", name: "Santo Tomas", state_id: "430" },
  { id: "2209", name: "Sasmuan", state_id: "430" },

  { id: "2210", name: "Agno", state_id: "431" },
  { id: "2211", name: "Aguilar", state_id: "431" },
  { id: "2212", name: "Alaminos", state_id: "431" },
  { id: "2213", name: "Alcala", state_id: "431" },
  { id: "2214", name: "Anda", state_id: "431" },
  { id: "2215", name: "Asingan", state_id: "431" },
  { id: "2216", name: "Balungao", state_id: "431" },
  { id: "2217", name: "Bani", state_id: "431" },
  { id: "2218", name: "Basista", state_id: "431" },
  { id: "2219", name: "Bautista", state_id: "431" },
  { id: "2220", name: "Bayambang", state_id: "431" },
  { id: "2221", name: "Binalonan", state_id: "431" },
  { id: "2222", name: "Binmaley", state_id: "431" },
  { id: "2223", name: "Bolinao", state_id: "431" },
  { id: "2224", name: "Bugallon", state_id: "431" },
  { id: "2225", name: "Burgos", state_id: "431" },
  { id: "2226", name: "Calasiao", state_id: "431" },
  { id: "2227", name: "Dagupan", state_id: "431" },
  { id: "2228", name: "Dasol", state_id: "431" },
  { id: "2229", name: "Infanta", state_id: "431" },
  { id: "2230", name: "Labrador", state_id: "431" },
  { id: "2231", name: "Laoac", state_id: "431" },
  { id: "2232", name: "Lingayen", state_id: "431" },
  { id: "2233", name: "Mabini", state_id: "431" },
  { id: "2234", name: "Malasiqui", state_id: "431" },
  { id: "2235", name: "Manaoag", state_id: "431" },
  { id: "2236", name: "Mangaldan", state_id: "431" },
  { id: "2237", name: "Mangatarem", state_id: "431" },
  { id: "2238", name: "Mapandan", state_id: "431" },
  { id: "2239", name: "Natividad", state_id: "431" },
  { id: "2240", name: "Pozorrubio", state_id: "431" },
  { id: "2241", name: "Rosales", state_id: "431" },
  { id: "2242", name: "San Carlos", state_id: "431" },
  { id: "2243", name: "San Fabian", state_id: "431" },
  { id: "2244", name: "San Jacinto", state_id: "431" },
  { id: "2245", name: "San Manuel", state_id: "431" },
  { id: "2246", name: "San Nicolas", state_id: "431" },
  { id: "2247", name: "San Quintin", state_id: "431" },
  { id: "2248", name: "Santa Barbara", state_id: "431" },
  { id: "2249", name: "Santa Maria", state_id: "431" },
  { id: "2250", name: "Santo Tomas", state_id: "431" },
  { id: "2251", name: "Sison", state_id: "431" },
  { id: "2252", name: "Sual", state_id: "431" },
  { id: "2253", name: "Tayug", state_id: "431" },
  { id: "2254", name: "Umingan", state_id: "431" },
  { id: "2255", name: "Urbiztondo", state_id: "431" },
  { id: "2256", name: "Urdaneta", state_id: "431" },
  { id: "2257", name: "Villasis", state_id: "431" },

  { id: "2258", name: "Agdangan", state_id: "432" },
  { id: "2259", name: "Alabat", state_id: "432" },
  { id: "2260", name: "Atimonan", state_id: "432" },
  { id: "2261", name: "Buenavista", state_id: "432" },
  { id: "2262", name: "Burdeos", state_id: "432" },
  { id: "2263", name: "Calauag", state_id: "432" },
  { id: "2264", name: "Candelaria", state_id: "432" },
  { id: "2265", name: "Catanauan", state_id: "432" },
  { id: "2266", name: "Dolores", state_id: "432" },
  { id: "2267", name: "General Luna", state_id: "432" },
  { id: "2268", name: "General Nakar", state_id: "432" },
  { id: "2269", name: "Guinayangan", state_id: "432" },
  { id: "2270", name: "Gumaca", state_id: "432" },
  { id: "2271", name: "Infanta", state_id: "432" },
  { id: "2272", name: "Jomalig", state_id: "432" },
  { id: "2273", name: "Lopez", state_id: "432" },
  { id: "2274", name: "Lucban", state_id: "432" },
  { id: "2275", name: "Lucena", state_id: "432" },
  { id: "2276", name: "Macalelon", state_id: "432" },
  { id: "2277", name: "Mauban", state_id: "432" },
  { id: "2278", name: "Mulanay", state_id: "432" },
  { id: "2279", name: "Padre Burgos", state_id: "432" },
  { id: "2280", name: "Pagbilao", state_id: "432" },
  { id: "2281", name: "Panukulan", state_id: "432" },
  { id: "2282", name: "Patnanungan", state_id: "432" },
  { id: "2283", name: "Perez", state_id: "432" },
  { id: "2284", name: "Pitogo", state_id: "432" },
  { id: "2285", name: "Plaridel", state_id: "432" },
  { id: "2286", name: "Polillo", state_id: "432" },
  { id: "2287", name: "Quezon", state_id: "432" },
  { id: "2288", name: "Real", state_id: "432" },
  { id: "2289", name: "Sampaloc", state_id: "432" },
  { id: "2290", name: "San Andres", state_id: "432" },
  { id: "2291", name: "San Antonio", state_id: "432" },
  { id: "2292", name: "San Francisco", state_id: "432" },
  { id: "2293", name: "San Narciso", state_id: "432" },
  { id: "2294", name: "Sariaya", state_id: "432" },
  { id: "2295", name: "Tagkawayan", state_id: "432" },
  { id: "2296", name: "Tayabas", state_id: "432" },
  { id: "2297", name: "Tiaong", state_id: "432" },
  { id: "2298", name: "Unisan", state_id: "432" },

  { id: "2299", name: "Cabarroguis", state_id: "433" },
  { id: "2300", name: "Diffun", state_id: "433" },
  { id: "2301", name: "Maddela", state_id: "433" },
  { id: "2302", name: "Nagtipunan", state_id: "433" },
  { id: "2303", name: "Saguday", state_id: "433" },

  { id: "2304", name: "Angono", state_id: "434" },
  { id: "2305", name: "Antipolo", state_id: "434" },
  { id: "2306", name: "Baras", state_id: "434" },
  { id: "2307", name: "Binangonan", state_id: "434" },
  { id: "2308", name: "Cainta", state_id: "434" },
  { id: "2309", name: "Cardona", state_id: "434" },
  { id: "2310", name: "Jalajala", state_id: "434" },
  { id: "2311", name: "Montalban", state_id: "434" },
  { id: "2312", name: "Morong", state_id: "434" },
  { id: "2313", name: "Pililla", state_id: "434" },
  { id: "2314", name: "Rodriguez", state_id: "434" },
  { id: "2315", name: "San Mateo", state_id: "434" },
  { id: "2316", name: "Tanay", state_id: "434" },
  { id: "2317", name: "Taytay", state_id: "434" },

  { id: "2318", name: "Alcantara", state_id: "435" },
  { id: "2319", name: "Banton", state_id: "435" },
  { id: "2320", name: "Cajidiocan", state_id: "435" },
  { id: "2321", name: "Calatrava", state_id: "435" },
  { id: "2322", name: "Concepcion", state_id: "435" },
  { id: "2323", name: "Ferrol", state_id: "435" },
  { id: "2324", name: "Magdiwang", state_id: "435" },
  { id: "2325", name: "Odiongan", state_id: "435" },
  { id: "2326", name: "Romblon", state_id: "435" },
  { id: "2327", name: "San Agustin", state_id: "435" },
  { id: "2328", name: "San Jose", state_id: "435" },
  { id: "2329", name: "Santa Fe", state_id: "435" },
  { id: "2330", name: "Tibor", state_id: "435" },

  { id: "2331", name: "Barcelona", state_id: "436" },
  { id: "2332", name: "Bulan", state_id: "436" },
  { id: "2333", name: "Casiguran", state_id: "436" },
  { id: "2334", name: "Donsol", state_id: "436" },
  { id: "2335", name: "Gubat", state_id: "436" },
  { id: "2336", name: "Irosin", state_id: "436" },
  { id: "2337", name: "Juban", state_id: "436" },
  { id: "2338", name: "Matnog", state_id: "436" },
  { id: "2339", name: "Prieto Diaz", state_id: "436" },
  { id: "2340", name: "Sorsogon City", state_id: "436" },
  { id: "2341", name: "Sta. Magdalena", state_id: "436" },

  { id: "2342", name: "Anao", state_id: "437" },
  { id: "2343", name: "Bamban", state_id: "437" },
  { id: "2344", name: "Camiling", state_id: "437" },
  { id: "2345", name: "Capas", state_id: "437" },
  { id: "2346", name: "Concepcion", state_id: "437" },
  { id: "2347", name: "La Paz", state_id: "437" },
  { id: "2348", name: "Mayantoc", state_id: "437" },
  { id: "2349", name: "Moncada", state_id: "437" },
  { id: "2350", name: "Pura", state_id: "437" },
  { id: "2351", name: "San Jose", state_id: "437" },
  { id: "2352", name: "San Manuel", state_id: "437" },
  { id: "2353", name: "San Rafael", state_id: "437" },
  { id: "2354", name: "Tarlac City", state_id: "437" },
  { id: "2355", name: "Victoria", state_id: "437" },

  { id: "2356", name: "Botolan", state_id: "438" },
  { id: "2357", name: "Cabangan", state_id: "438" },
  { id: "2358", name: "Candelaria", state_id: "438" },
  { id: "2359", name: "Castillejos", state_id: "438" },
  { id: "2360", name: "Iba", state_id: "438" },
  { id: "2361", name: "Masinloc", state_id: "438" },
  { id: "2362", name: "Olongapo", state_id: "438" },
  { id: "2363", name: "Palauig", state_id: "438" },
  { id: "2364", name: "San Antonio", state_id: "438" },
  { id: "2365", name: "San Felipe", state_id: "438" },
  { id: "2366", name: "San Marcelino", state_id: "438" },
  { id: "2367", name: "San Narciso", state_id: "438" },
  { id: "2368", name: "Santa Cruz", state_id: "438" },
  { id: "2369", name: "Subic", state_id: "438" },

  { id: "2370", name: "Altavas", state_id: "439" },
  { id: "2371", name: "Balete", state_id: "439" },
  { id: "2372", name: "Banga", state_id: "439" },
  { id: "2373", name: "Batan", state_id: "439" },
  { id: "2374", name: "Ibajay", state_id: "439" },
  { id: "2375", name: "Kalibo", state_id: "439" },
  { id: "2376", name: "Lezo", state_id: "439" },
  { id: "2377", name: "Libacao", state_id: "439" },
  { id: "2378", name: "Madalag", state_id: "439" },
  { id: "2379", name: "Makato", state_id: "439" },
  { id: "2380", name: "Malinao", state_id: "439" },
  { id: "2381", name: "Nabas", state_id: "439" },
  { id: "2382", name: "New Washington", state_id: "439" },
  { id: "2383", name: "Numancia", state_id: "439" },
  { id: "2384", name: "Tangalan", state_id: "439" },

  { id: "2385", name: "Anini-y", state_id: "440" },
  { id: "2386", name: "Bandal", state_id: "440" },
  { id: "2387", name: "Bugasong", state_id: "440" },
  { id: "2388", name: "Caluya", state_id: "440" },
  { id: "2389", name: "Culasi", state_id: "440" },
  { id: "2390", name: "Hamtic", state_id: "440" },
  { id: "2391", name: "San Jose de Buenavista", state_id: "440" },
  { id: "2392", name: "San Remigio", state_id: "440" },
  { id: "2393", name: "Sibalom", state_id: "440" },
  { id: "2394", name: "Tibiao", state_id: "440" },
  { id: "2395", name: "Valderrama", state_id: "440" },

  { id: "2396", name: "Alburquerque", state_id: "441" },
  { id: "2397", name: "Baclayon", state_id: "441" },
  { id: "2398", name: "Batuan", state_id: "441" },
  { id: "2399", name: "Buenavista", state_id: "441" },
  { id: "2400", name: "Catigbian", state_id: "441" },
  { id: "2401", name: "Clarin", state_id: "441" },
  { id: "2402", name: "Corella", state_id: "441" },
  { id: "2403", name: "Cortes", state_id: "441" },
  { id: "2404", name: "Dagohoy", state_id: "441" },
  { id: "2405", name: "Danao", state_id: "441" },
  { id: "2406", name: "Dumaliang", state_id: "441" },
  { id: "2407", name: "García-Hernández", state_id: "441" },
  { id: "2408", name: "Getafe", state_id: "441" },
  { id: "2409", name: "Inabanga", state_id: "441" },
  { id: "2410", name: "Loon", state_id: "441" },
  { id: "2411", name: "Maribojoc", state_id: "441" },
  { id: "2412", name: "Panglao", state_id: "441" },
  { id: "2413", name: "Sagbayan", state_id: "441" },
  { id: "2414", name: "San Isidro", state_id: "441" },
  { id: "2415", name: "Sierra Bullones", state_id: "441" },
  { id: "2416", name: "Talibon", state_id: "441" },
  { id: "2417", name: "Trinidad", state_id: "441" },
  { id: "2418", name: "Tubigon", state_id: "441" },

  { id: "2419", name: "Ayub", state_id: "442" },
  { id: "2420", name: "Dumalag", state_id: "442" },
  { id: "2421", name: "Dumarao", state_id: "442" },
  { id: "2422", name: "Ivisan", state_id: "442" },
  { id: "2423", name: "Jamindan", state_id: "442" },
  { id: "2424", name: "Mambusao", state_id: "442" },
  { id: "2425", name: "Panay", state_id: "442" },
  { id: "2426", name: "Pilar", state_id: "442" },
  { id: "2427", name: "Roxas City", state_id: "442" },
  { id: "2428", name: "Tapaz", state_id: "442" },

  { id: "2429", name: "Adlaon", state_id: "443" },
  { id: "2430", name: "Alcantara", state_id: "443" },
  { id: "2431", name: "Alegria", state_id: "443" },
  { id: "2432", name: "Argao", state_id: "443" },
  { id: "2433", name: "Badian", state_id: "443" },
  { id: "2434", name: "Balamban", state_id: "443" },
  { id: "2435", name: "Bantayan", state_id: "443" },
  { id: "2436", name: "Barili", state_id: "443" },
  { id: "2437", name: "Bogo", state_id: "443" },
  { id: "2438", name: "Boljoon", state_id: "443" },
  { id: "2439", name: "Carmen", state_id: "443" },
  { id: "2440", name: "Catmon", state_id: "443" },
  { id: "2441", name: "Cebu City", state_id: "443" },
  { id: "2442", name: "Compostela", state_id: "443" },
  { id: "2443", name: "Cordova", state_id: "443" },
  { id: "2444", name: "Danao City", state_id: "443" },
  { id: "2445", name: "Dalaguete", state_id: "443" },
  { id: "2446", name: "Lapu-Lapu City", state_id: "443" },
  { id: "2447", name: "Mandaue City", state_id: "443" },
  { id: "2448", name: "Medellin", state_id: "443" },
  { id: "2449", name: "Moalboal", state_id: "443" },
  { id: "2450", name: "Naga City", state_id: "443" },
  { id: "2451", name: "Oslob", state_id: "443" },
  { id: "2452", name: "Pinamungajan", state_id: "443" },
  { id: "2453", name: "San Fernando", state_id: "443" },
  { id: "2454", name: "San Francisco", state_id: "443" },
  { id: "2455", name: "Santa Fe", state_id: "443" },
  { id: "2456", name: "Samboan", state_id: "443" },
  { id: "2457", name: "Toledo City", state_id: "443" },
  { id: "2458", name: "Talisay City", state_id: "443" },
  { id: "2459", name: "Tuburan", state_id: "443" },
  { id: "2460", name: "Ubay", state_id: "443" },
  { id: "2461", name: "Victoria", state_id: "443" },

  { id: "2462", name: "Arteche", state_id: "444" },
  { id: "2463", name: "Balangiga", state_id: "444" },
  { id: "2464", name: "Balangkayan", state_id: "444" },
  { id: "2465", name: "Borongan", state_id: "444" },
  { id: "2466", name: "Can-avid", state_id: "444" },
  { id: "2467", name: "Dolores", state_id: "444" },
  { id: "2468", name: "General MacArthur", state_id: "444" },
  { id: "2469", name: "Giporlos", state_id: "444" },
  { id: "2470", name: "Guiuan", state_id: "444" },
  { id: "2471", name: "Hernani", state_id: "444" },
  { id: "2472", name: "Jipapad", state_id: "444" },
  { id: "2473", name: "Lawaan", state_id: "444" },
  { id: "2474", name: "Llorente", state_id: "444" },
  { id: "2475", name: "Maslog", state_id: "444" },
  { id: "2476", name: "Maydolong", state_id: "444" },
  { id: "2477", name: "Mercedes", state_id: "444" },
  { id: "2478", name: "Oras", state_id: "444" },
  { id: "2479", name: "Quinapondan", state_id: "444" },
  { id: "2480", name: "Salcedo", state_id: "444" },
  { id: "2481", name: "San Julian", state_id: "444" },
  { id: "2482", name: "San Policarpo", state_id: "444" },
  { id: "2483", name: "Sulat", state_id: "444" },
  { id: "2484", name: "Taft", state_id: "444" },

  { id: "2485", name: "Buenavista", state_id: "445" },
  { id: "2486", name: "Jordan", state_id: "445" },
  { id: "2487", name: "Nueva Valencia", state_id: "445" },
  { id: "2488", name: "San Lorenzo", state_id: "445" },
  { id: "2489", name: "Sibunag", state_id: "445" },

  { id: "2490", name: "Ajuy", state_id: "446" },
  { id: "2491", name: "Alimodian", state_id: "446" },
  { id: "2492", name: "Anilao", state_id: "446" },
  { id: "2493", name: "Badiangan", state_id: "446" },
  { id: "2494", name: "Balasan", state_id: "446" },
  { id: "2495", name: "Banate", state_id: "446" },
  { id: "2496", name: "Barotac Nuevo", state_id: "446" },
  { id: "2497", name: "Barotac Viejo", state_id: "446" },
  { id: "2498", name: "Batad", state_id: "446" },
  { id: "2499", name: "Bingawan", state_id: "446" },
  { id: "2500", name: "Cabatuan", state_id: "446" },
  { id: "2501", name: "Calinog", state_id: "446" },
  { id: "2502", name: "Carles", state_id: "446" },
  { id: "2503", name: "Concepcion", state_id: "446" },
  { id: "2504", name: "Dingle", state_id: "446" },
  { id: "2505", name: "Dueñas", state_id: "446" },
  { id: "2506", name: "Dumangas", state_id: "446" },
  { id: "2507", name: "Estancia", state_id: "446" },
  { id: "2508", name: "Guimbal", state_id: "446" },
  { id: "2509", name: "Igbaras", state_id: "446" },
  { id: "2510", name: "Iloilo City", state_id: "446" },
  { id: "2511", name: "Janiuay", state_id: "446" },
  { id: "2512", name: "Lambunao", state_id: "446" },
  { id: "2513", name: "Leganes", state_id: "446" },
  { id: "2514", name: "Lemery", state_id: "446" },
  { id: "2515", name: "Leon", state_id: "446" },
  { id: "2516", name: "Maasin", state_id: "446" },
  { id: "2517", name: "Miagao", state_id: "446" },
  { id: "2518", name: "Mina", state_id: "446" },
  { id: "2519", name: "New Lucena", state_id: "446" },
  { id: "2520", name: "Oton", state_id: "446" },
  { id: "2521", name: "Pavia", state_id: "446" },
  { id: "2522", name: "Pototan", state_id: "446" },
  { id: "2523", name: "San Dionisio", state_id: "446" },
  { id: "2524", name: "San Enrique", state_id: "446" },
  { id: "2525", name: "San Joaquin", state_id: "446" },
  { id: "2526", name: "San Miguel", state_id: "446" },
  { id: "2527", name: "San Rafael", state_id: "446" },
  { id: "2528", name: "Santa Barbara", state_id: "446" },
  { id: "2529", name: "Sara", state_id: "446" },
  { id: "2530", name: "Tigbauan", state_id: "446" },
  { id: "2531", name: "Tubungan", state_id: "446" },
  { id: "2532", name: "Zarraga", state_id: "446" },

  { id: "2533", name: "Abuyog", state_id: "447" },
  { id: "2534", name: "Alangalang", state_id: "447" },
  { id: "2535", name: "Albuera", state_id: "447" },
  { id: "2536", name: "Babatngon", state_id: "447" },
  { id: "2537", name: "Barugo", state_id: "447" },
  { id: "2538", name: "Bato", state_id: "447" },
  { id: "2539", name: "Baybay City", state_id: "447" },
  { id: "2540", name: "Burauen", state_id: "447" },
  { id: "2541", name: "Calubian", state_id: "447" },
  { id: "2542", name: "Capoocan", state_id: "447" },
  { id: "2543", name: "Carigara", state_id: "447" },
  { id: "2544", name: "Dagami", state_id: "447" },
  { id: "2545", name: "Dulag", state_id: "447" },
  { id: "2546", name: "Hilongos", state_id: "447" },
  { id: "2547", name: "Hindang", state_id: "447" },
  { id: "2548", name: "Inopacan", state_id: "447" },
  { id: "2549", name: "Isabel", state_id: "447" },
  { id: "2550", name: "Jaro", state_id: "447" },
  { id: "2551", name: "Javier", state_id: "447" },
  { id: "2552", name: "Julita", state_id: "447" },
  { id: "2553", name: "Kananga", state_id: "447" },
  { id: "2554", name: "La Paz", state_id: "447" },
  { id: "2555", name: "Leyte", state_id: "447" },
  { id: "2556", name: "MacArthur", state_id: "447" },
  { id: "2557", name: "Mahaplag", state_id: "447" },
  { id: "2558", name: "Matag-ob", state_id: "447" },
  { id: "2559", name: "Matalom", state_id: "447" },
  { id: "2560", name: "Mayorga", state_id: "447" },
  { id: "2561", name: "Merida", state_id: "447" },
  { id: "2562", name: "Ormoc City", state_id: "447" },
  { id: "2563", name: "Palo", state_id: "447" },
  { id: "2564", name: "Palompon", state_id: "447" },
  { id: "2565", name: "Pastrana", state_id: "447" },
  { id: "2566", name: "San Isidro", state_id: "447" },
  { id: "2567", name: "San Miguel", state_id: "447" },
  { id: "2568", name: "Santa Fe", state_id: "447" },
  { id: "2569", name: "Tabango", state_id: "447" },
  { id: "2570", name: "Tabontabon", state_id: "447" },
  { id: "2571", name: "Tacloban City", state_id: "447" },
  { id: "2572", name: "Tanauan", state_id: "447" },
  { id: "2573", name: "Tolosa", state_id: "447" },
  { id: "2574", name: "Tunga", state_id: "447" },
  { id: "2575", name: "Villaba", state_id: "447" },

  { id: "2576", name: "Bacolod City", state_id: "448" },
  { id: "2577", name: "Bago City", state_id: "448" },
  { id: "2578", name: "Binalbagan", state_id: "448" },
  { id: "2579", name: "Cadiz City", state_id: "448" },
  { id: "2580", name: "Calatrava", state_id: "448" },
  { id: "2581", name: "Candoni", state_id: "448" },
  { id: "2582", name: "Cauayan", state_id: "448" },
  { id: "2583", name: "Enrique B. Magalona", state_id: "448" },
  { id: "2584", name: "Escalante City", state_id: "448" },
  { id: "2585", name: "Himamaylan City", state_id: "448" },
  { id: "2586", name: "Hinigaran", state_id: "448" },
  { id: "2587", name: "Hinoba-an", state_id: "448" },
  { id: "2588", name: "Ilog", state_id: "448" },
  { id: "2589", name: "Isabela", state_id: "448" },
  { id: "2590", name: "Kabankalan City", state_id: "448" },
  { id: "2591", name: "La Carlota City", state_id: "448" },
  { id: "2592", name: "La Castellana", state_id: "448" },
  { id: "2593", name: "Manapla", state_id: "448" },
  { id: "2594", name: "Moises Padilla", state_id: "448" },
  { id: "2595", name: "Murcia", state_id: "448" },
  { id: "2596", name: "Pontevedra", state_id: "448" },
  { id: "2597", name: "Pulupandan", state_id: "448" },
  { id: "2598", name: "Sagay City", state_id: "448" },
  { id: "2599", name: "Salvador Benedicto", state_id: "448" },
  { id: "2600", name: "San Carlos City", state_id: "448" },
  { id: "2601", name: "San Enrique", state_id: "448" },
  { id: "2602", name: "Silay City", state_id: "448" },
  { id: "2603", name: "Sipalay City", state_id: "448" },
  { id: "2604", name: "Talisay City", state_id: "448" },
  { id: "2605", name: "Toboso", state_id: "448" },
  { id: "2606", name: "Valladolid", state_id: "448" },
  { id: "2607", name: "Victorias City", state_id: "448" },

  { id: "2608", name: "Amlan", state_id: "449" },
  { id: "2609", name: "Ayungon", state_id: "449" },
  { id: "2610", name: "Bacong", state_id: "449" },
  { id: "2611", name: "Bais City", state_id: "449" },
  { id: "2612", name: "Basay", state_id: "449" },
  { id: "2613", name: "Bayawan City", state_id: "449" },
  { id: "2614", name: "Bindoy", state_id: "449" },
  { id: "2615", name: "Canlaon City", state_id: "449" },
  { id: "2616", name: "Dauin", state_id: "449" },
  { id: "2617", name: "Dumaguete City", state_id: "449" },
  { id: "2618", name: "Guihulngan City", state_id: "449" },
  { id: "2619", name: "Jimalalud", state_id: "449" },
  { id: "2620", name: "La Libertad", state_id: "449" },
  { id: "2621", name: "Mabinay", state_id: "449" },
  { id: "2622", name: "Manjuyod", state_id: "449" },
  { id: "2623", name: "Pamplona", state_id: "449" },
  { id: "2624", name: "San Jose", state_id: "449" },
  { id: "2625", name: "Santa Catalina", state_id: "449" },
  { id: "2626", name: "Siaton", state_id: "449" },
  { id: "2627", name: "Sibulan", state_id: "449" },
  { id: "2628", name: "Tanjay City", state_id: "449" },
  { id: "2629", name: "Tayasan", state_id: "449" },
  { id: "2630", name: "Valencia", state_id: "449" },
  { id: "2631", name: "Vallehermoso", state_id: "449" },
  { id: "2632", name: "Zamboanguita", state_id: "449" },

  { id: "2633", name: "Allen", state_id: "450" },
  { id: "2634", name: "Biri", state_id: "450" },
  { id: "2635", name: "Bobon", state_id: "450" },
  { id: "2636", name: "Capul", state_id: "450" },
  { id: "2637", name: "Catarman", state_id: "450" },
  { id: "2638", name: "Catubig", state_id: "450" },
  { id: "2639", name: "Gamay", state_id: "450" },
  { id: "2640", name: "Laoang", state_id: "450" },
  { id: "2641", name: "Lapinig", state_id: "450" },
  { id: "2642", name: "Las Navas", state_id: "450" },
  { id: "2643", name: "Lavezares", state_id: "450" },
  { id: "2644", name: "Mapanas", state_id: "450" },
  { id: "2645", name: "Mondragon", state_id: "450" },
  { id: "2646", name: "Palapag", state_id: "450" },
  { id: "2647", name: "Pambujan", state_id: "450" },
  { id: "2648", name: "Rosario", state_id: "450" },
  { id: "2649", name: "San Antonio", state_id: "450" },
  { id: "2650", name: "San Isidro", state_id: "450" },
  { id: "2651", name: "San Jose", state_id: "450" },
  { id: "2652", name: "San Roque", state_id: "450" },
  { id: "2653", name: "San Vicente", state_id: "450" },
  { id: "2654", name: "Silvino Lobos", state_id: "450" },
  { id: "2655", name: "Victoria", state_id: "450" },

  { id: "2656", name: "Almagro", state_id: "451" },
  { id: "2657", name: "Basey", state_id: "451" },
  { id: "2658", name: "Calbayog City", state_id: "451" },
  { id: "2659", name: "Calbiga", state_id: "451" },
  { id: "2660", name: "Catbalogan City", state_id: "451" },
  { id: "2661", name: "Daram", state_id: "451" },
  { id: "2662", name: "Gandara", state_id: "451" },
  { id: "2663", name: "Hinabangan", state_id: "451" },
  { id: "2664", name: "Jiabong", state_id: "451" },
  { id: "2665", name: "Marabut", state_id: "451" },
  { id: "2666", name: "Matuguinao", state_id: "451" },
  { id: "2667", name: "Motiong", state_id: "451" },
  { id: "2668", name: "Pagsanghan", state_id: "451" },
  { id: "2669", name: "Paranas", state_id: "451" },
  { id: "2670", name: "Pinabacdao", state_id: "451" },
  { id: "2671", name: "San Jorge", state_id: "451" },
  { id: "2672", name: "San Jose de Buan", state_id: "451" },
  { id: "2673", name: "San Sebastian", state_id: "451" },
  { id: "2674", name: "Santa Margarita", state_id: "451" },
  { id: "2675", name: "Santa Rita", state_id: "451" },
  { id: "2676", name: "Santo Niño", state_id: "451" },
  { id: "2677", name: "Tagapul-an", state_id: "451" },
  { id: "2678", name: "Talalora", state_id: "451" },
  { id: "2679", name: "Tarangnan", state_id: "451" },
  { id: "2680", name: "Villareal", state_id: "451" },
  { id: "2681", name: "Zumarraga", state_id: "451" },

  { id: "2682", name: "Enrique Villanueva", state_id: "452" },
  { id: "2683", name: "Larena", state_id: "452" },
  { id: "2684", name: "Lazi", state_id: "452" },
  { id: "2685", name: "Maria", state_id: "452" },
  { id: "2686", name: "San Juan", state_id: "452" },
  { id: "2687", name: "Siquijor", state_id: "452" },

  { id: "2688", name: "Anahawan", state_id: "453" },
  { id: "2689", name: "Bontoc", state_id: "453" },
  { id: "2690", name: "Hinunangan", state_id: "453" },
  { id: "2691", name: "Hinundayan", state_id: "453" },
  { id: "2692", name: "Libagon", state_id: "453" },
  { id: "2693", name: "Liloan", state_id: "453" },
  { id: "2694", name: "Maasin City", state_id: "453" },
  { id: "2695", name: "Macrohon", state_id: "453" },
  { id: "2696", name: "Malitbog", state_id: "453" },
  { id: "2697", name: "Padre Burgos", state_id: "453" },
  { id: "2698", name: "Pintuyan", state_id: "453" },
  { id: "2699", name: "Saint Bernard", state_id: "453" },
  { id: "2700", name: "San Francisco", state_id: "453" },
  { id: "2701", name: "San Juan", state_id: "453" },
  { id: "2702", name: "San Ricardo", state_id: "453" },
  { id: "2703", name: "Silago", state_id: "453" },
  { id: "2704", name: "Sogod", state_id: "453" },
  { id: "2705", name: "Tomas Oppus", state_id: "453" },

  { id: "2706", name: "Buenavista", state_id: "454" },
  { id: "2707", name: "Butuan City", state_id: "454" },
  { id: "2708", name: "Cabadbaran City", state_id: "454" },
  { id: "2709", name: "Carmen", state_id: "454" },
  { id: "2710", name: "Jabonga", state_id: "454" },
  { id: "2711", name: "Kitcharao", state_id: "454" },
  { id: "2712", name: "Las Nieves", state_id: "454" },
  { id: "2713", name: "Magallanes", state_id: "454" },
  { id: "2714", name: "Nasipit", state_id: "454" },
  { id: "2715", name: "Remedios T. Romualdez", state_id: "454" },
  { id: "2716", name: "Santiago", state_id: "454" },

  { id: "2717", name: "Bayugan City", state_id: "455" },
  { id: "2718", name: "Bunawan", state_id: "455" },
  { id: "2719", name: "Esperanza", state_id: "455" },
  { id: "2720", name: "La Paz", state_id: "455" },
  { id: "2721", name: "Loreto", state_id: "455" },
  { id: "2722", name: "Prosperidad", state_id: "455" },
  { id: "2723", name: "Rosario", state_id: "455" },
  { id: "2724", name: "San Francisco", state_id: "455" },
  { id: "2725", name: "San Luis", state_id: "455" },
  { id: "2726", name: "Santa Josefa", state_id: "455" },
  { id: "2727", name: "Sibagat", state_id: "455" },
  { id: "2728", name: "Talacogon", state_id: "455" },
  { id: "2729", name: "Trento", state_id: "455" },
  { id: "2730", name: "Veruela", state_id: "455" },

  { id: "2731", name: "Akbar", state_id: "456" },
  { id: "2732", name: "Al-Barka", state_id: "456" },
  { id: "2733", name: "Hadji Mohammad Ajul", state_id: "456" },
  { id: "2734", name: "Hadji Muhtamad", state_id: "456" },
  { id: "2735", name: "Isabela City", state_id: "456" },
  { id: "2736", name: "Lamitan City", state_id: "456" },
  { id: "2737", name: "Lantawan", state_id: "456" },
  { id: "2738", name: "Maluso", state_id: "456" },
  { id: "2739", name: "Sumisip", state_id: "456" },
  { id: "2740", name: "Tabuan-Lasa", state_id: "456" },
  { id: "2741", name: "Tipo-Tipo", state_id: "456" },
  { id: "2742", name: "Tuburan", state_id: "456" },
  { id: "2743", name: "Ungkaya Pukan", state_id: "456" },

  { id: "2744", name: "Baungon", state_id: "457" },
  { id: "2745", name: "Cabanglasan", state_id: "457" },
  { id: "2746", name: "Damulog", state_id: "457" },
  { id: "2747", name: "Dangcagan", state_id: "457" },
  { id: "2748", name: "Don Carlos", state_id: "457" },
  { id: "2749", name: "Impasugong", state_id: "457" },
  { id: "2750", name: "Kadingilan", state_id: "457" },
  { id: "2751", name: "Kalilangan", state_id: "457" },
  { id: "2752", name: "Kibawe", state_id: "457" },
  { id: "2753", name: "Kitaotao", state_id: "457" },
  { id: "2754", name: "Lantapan", state_id: "457" },
  { id: "2755", name: "Libona", state_id: "457" },
  { id: "2756", name: "Malabalay City", state_id: "457" },
  { id: "2757", name: "Manolo Fortich", state_id: "457" },
  { id: "2758", name: "Maramag", state_id: "457" },
  { id: "2759", name: "Pangantucan", state_id: "457" },
  { id: "2760", name: "Quezon", state_id: "457" },
  { id: "2761", name: "San Fernando", state_id: "457" },
  { id: "2762", name: "Sumilao", state_id: "457" },
  { id: "2763", name: "Talakag", state_id: "457" },
  { id: "2764", name: "Valencia City", state_id: "457" },

  { id: "2765", name: "Catarman", state_id: "458" },
  { id: "2766", name: "Guinsiliban", state_id: "458" },
  { id: "2767", name: "Mahinog", state_id: "458" },
  { id: "2768", name: "Mambajao", state_id: "458" },
  { id: "2769", name: "Sagay", state_id: "458" },

  { id: "2770", name: "Compostela", state_id: "459" },
  { id: "2771", name: "Laak", state_id: "459" },
  { id: "2772", name: "Mabini", state_id: "459" },
  { id: "2773", name: "Maco", state_id: "459" },
  { id: "2774", name: "Maragusan", state_id: "459" },
  { id: "2775", name: "Mawab", state_id: "459" },
  { id: "2776", name: "Monkayo", state_id: "459" },
  { id: "2777", name: "Montevista", state_id: "459" },
  { id: "2778", name: "Nabunturan", state_id: "459" },
  { id: "2779", name: "New Bataan", state_id: "459" },
  { id: "2780", name: "Pantukan", state_id: "459" },

  { id: "2781", name: "Asuncion", state_id: "460" },
  { id: "2782", name: "Braulio E. Dujali", state_id: "460" },
  { id: "2783", name: "Carmen", state_id: "460" },
  { id: "2784", name: "Kapalong", state_id: "460" },
  { id: "2785", name: "New Corella", state_id: "460" },
  { id: "2786", name: "Panabo City", state_id: "460" },
  { id: "2787", name: "Samal City", state_id: "460" },
  { id: "2788", name: "San Isidro", state_id: "460" },
  { id: "2789", name: "Santo Tomas", state_id: "460" },
  { id: "2790", name: "Tagum City", state_id: "460" },
  { id: "2791", name: "Talaingod", state_id: "460" },

  { id: "2792", name: "Bansalan", state_id: "461" },
  { id: "2793", name: "Davao City", state_id: "461" },
  { id: "2794", name: "Digos City", state_id: "461" },
  { id: "2795", name: "Hagonoy", state_id: "461" },
  { id: "2796", name: "Kiblawan", state_id: "461" },
  { id: "2797", name: "Magsaysay", state_id: "461" },
  { id: "2798", name: "Malalag", state_id: "461" },
  { id: "2799", name: "Matanao", state_id: "461" },
  { id: "2800", name: "Padada", state_id: "461" },
  { id: "2801", name: "Santa Cruz", state_id: "461" },
  { id: "2802", name: "Sulop", state_id: "461" },

  { id: "2803", name: "Don Marcelino", state_id: "462" },
  { id: "2804", name: "Jose Abad Santos", state_id: "462" },
  { id: "2805", name: "Malita", state_id: "462" },
  { id: "2806", name: "Santa Maria", state_id: "462" },
  { id: "2807", name: "Sarangani", state_id: "462" },

  { id: "2808", name: "Baganga", state_id: "463" },
  { id: "2809", name: "Banaybanay", state_id: "463" },
  { id: "2810", name: "Boston", state_id: "463" },
  { id: "2811", name: "Caraga", state_id: "463" },
  { id: "2812", name: "Cateel", state_id: "463" },
  { id: "2813", name: "Governor Generoso", state_id: "463" },
  { id: "2814", name: "Lupon", state_id: "463" },
  { id: "2815", name: "Manay", state_id: "463" },
  { id: "2816", name: "Mati City", state_id: "463" },
  { id: "2817", name: "San Isidro", state_id: "463" },
  { id: "2818", name: "Tarragona", state_id: "463" },

  { id: "2819", name: "Basilisa", state_id: "464" },
  { id: "2820", name: "Cagdianao", state_id: "464" },
  { id: "2821", name: "Dinagat", state_id: "464" },
  { id: "2822", name: "Libjo", state_id: "464" },
  { id: "2823", name: "Loreto", state_id: "464" },
  { id: "2824", name: "San Jose", state_id: "464" },
  { id: "2825", name: "Tubajon", state_id: "464" },

  { id: "2826", name: "Bacolod", state_id: "465" },
  { id: "2827", name: "Baloi", state_id: "465" },
  { id: "2828", name: "Baroy", state_id: "465" },
  { id: "2829", name: "Kapatagan", state_id: "465" },
  { id: "2830", name: "Kauswagan", state_id: "465" },
  { id: "2831", name: "Kolambugan", state_id: "465" },
  { id: "2832", name: "Lala", state_id: "465" },
  { id: "2833", name: "Linamon", state_id: "465" },
  { id: "2834", name: "Magsaysay", state_id: "465" },
  { id: "2835", name: "Maigo", state_id: "465" },
  { id: "2836", name: "Matungao", state_id: "465" },
  { id: "2837", name: "Munai", state_id: "465" },
  { id: "2838", name: "Nunungan", state_id: "465" },
  { id: "2839", name: "Pantao Ragat", state_id: "465" },
  { id: "2840", name: "Pantar", state_id: "465" },
  { id: "2841", name: "Poona Piagapo", state_id: "465" },
  { id: "2842", name: "Salvador", state_id: "465" },
  { id: "2843", name: "Sapad", state_id: "465" },
  { id: "2844", name: "Sultan Naga Dimaporo", state_id: "465" },
  { id: "2845", name: "Tagoloan", state_id: "465" },
  { id: "2846", name: "Tangcal", state_id: "465" },
  { id: "2847", name: "Tubod", state_id: "465" },

  { id: "2848", name: "Bacolod-Kalawi", state_id: "466" },
  { id: "2849", name: "Balabagan", state_id: "466" },
  { id: "2850", name: "Balindong", state_id: "466" },
  { id: "2851", name: "Bayang", state_id: "466" },
  { id: "2852", name: "Binidayan", state_id: "466" },
  { id: "2853", name: "Buadiposo-Buntong", state_id: "466" },
  { id: "2854", name: "Bubong", state_id: "466" },
  { id: "2855", name: "Butig", state_id: "466" },
  { id: "2856", name: "Calanogas", state_id: "466" },
  { id: "2857", name: "Ditsaan-Ramain", state_id: "466" },
  { id: "2858", name: "Ganassi", state_id: "466" },
  { id: "2859", name: "Kapai", state_id: "466" },
  { id: "2860", name: "Kapatagan", state_id: "466" },
  { id: "2861", name: "Lumba-Bayabao", state_id: "466" },
  { id: "2862", name: "Lumbaca-Unayan", state_id: "466" },
  { id: "2863", name: "Lumbatan", state_id: "466" },
  { id: "2864", name: "Lumbayanague", state_id: "466" },
  { id: "2865", name: "Madalum", state_id: "466" },
  { id: "2866", name: "Madamba", state_id: "466" },
  { id: "2867", name: "Maguing", state_id: "466" },
  { id: "2868", name: "Malabang", state_id: "466" },
  { id: "2869", name: "Marantao", state_id: "466" },
  { id: "2870", name: "Marawi City", state_id: "466" },
  { id: "2871", name: "Marogong", state_id: "466" },
  { id: "2872", name: "Masiu", state_id: "466" },
  { id: "2873", name: "Mulondo", state_id: "466" },
  { id: "2874", name: "Pagayawan", state_id: "466" },
  { id: "2875", name: "Piagapo", state_id: "466" },
  { id: "2876", name: "Poona Bayabao", state_id: "466" },
  { id: "2877", name: "Pualas", state_id: "466" },
  { id: "2878", name: "Saguiaran", state_id: "466" },
  { id: "2879", name: "Sultan Dumalondong", state_id: "466" },
  { id: "2880", name: "Tagoloan II", state_id: "466" },
  { id: "2881", name: "Tamparan", state_id: "466" },
  { id: "2882", name: "Taraka", state_id: "466" },
  { id: "2883", name: "Tubaran", state_id: "466" },
  { id: "2884", name: "Tugaya", state_id: "466" },
  { id: "2885", name: "Wao", state_id: "466" },

  { id: "2886", name: "Ampatuan", state_id: "467" },
  { id: "2887", name: "Barira", state_id: "467" },
  { id: "2888", name: "Buldon", state_id: "467" },
  { id: "2889", name: "Buluan", state_id: "467" },
  { id: "2890", name: "Cotabato City", state_id: "467" },
  { id: "2891", name: "Datu Abdullah Sangki", state_id: "467" },
  { id: "2892", name: "Datu Anggal Midtimbang", state_id: "467" },
  { id: "2893", name: "Datu Hoffer Ampatuan", state_id: "467" },
  { id: "2894", name: "Datu Montawal", state_id: "467" },
  { id: "2895", name: "Datu Odin Sinsuat", state_id: "467" },
  { id: "2896", name: "Datu Paglas", state_id: "467" },
  { id: "2897", name: "Datu Piang", state_id: "467" },
  { id: "2898", name: "Datu Salibo", state_id: "467" },
  { id: "2899", name: "Datu Saudi-Ampatuan", state_id: "467" },
  { id: "2900", name: "Datu Unsay", state_id: "467" },
  { id: "2901", name: "Gen. S. K. Pendatun", state_id: "467" },
  { id: "2902", name: "Guindulungan", state_id: "467" },
  { id: "2903", name: "Kabuntalan", state_id: "467" },
  { id: "2904", name: "Mamasapano", state_id: "467" },
  { id: "2905", name: "Mangudadatu", state_id: "467" },
  { id: "2906", name: "Matanog", state_id: "467" },
  { id: "2907", name: "Northern Kabuntalan", state_id: "467" },
  { id: "2908", name: "Pagalungan", state_id: "467" },
  { id: "2909", name: "Paglat", state_id: "467" },
  { id: "2910", name: "Pandag", state_id: "467" },
  { id: "2911", name: "Parang", state_id: "467" },
  { id: "2912", name: "Rajah Buayan", state_id: "467" },
  { id: "2913", name: "Shariff Aguak", state_id: "467" },
  { id: "2914", name: "Shariff Saydona Mustapha", state_id: "467" },
  { id: "2915", name: "South Upi", state_id: "467" },
  { id: "2916", name: "Sultan Kudarat", state_id: "467" },
  { id: "2917", name: "Sultan Mastura", state_id: "467" },
  { id: "2918", name: "Sultan sa Barongis", state_id: "467" },
  { id: "2919", name: "Talayan", state_id: "467" },
  { id: "2920", name: "Talitay", state_id: "467" },
  { id: "2921", name: "Upi", state_id: "467" },

  { id: "2922", name: "Aloran", state_id: "468" },
  { id: "2923", name: "Baliangao", state_id: "468" },
  { id: "2924", name: "Bonifacio", state_id: "468" },
  { id: "2925", name: "Clarin", state_id: "468" },
  { id: "2926", name: "Jimenez", state_id: "468" },
  { id: "2927", name: "Oroquieta City", state_id: "468" },
  { id: "2928", name: "Plaridel", state_id: "468" },
  { id: "2929", name: "Sapang Dalaga", state_id: "468" },
  { id: "2930", name: "Sinacaban", state_id: "468" },
  { id: "2931", name: "Tangub City", state_id: "468" },

  { id: "2932", name: "Angalapan", state_id: "469" },
  { id: "2933", name: "Balingasag", state_id: "469" },
  { id: "2934", name: "Binuangan", state_id: "469" },
  { id: "2935", name: "Cagayan de Oro City", state_id: "469" },
  { id: "2936", name: "Claveria", state_id: "469" },
  { id: "2937", name: "Gingoog City", state_id: "469" },
  { id: "2938", name: "Initao", state_id: "469" },
  { id: "2939", name: "Jasaan", state_id: "469" },
  { id: "2940", name: "Lagonglong", state_id: "469" },
  { id: "2941", name: "Libertad", state_id: "469" },
  { id: "2942", name: "Manticao", state_id: "469" },
  { id: "2943", name: "Medina", state_id: "469" },
  { id: "2944", name: "Nazareth", state_id: "469" },
  { id: "2945", name: "Opol", state_id: "469" },
  { id: "2946", name: "Sugbongcogon", state_id: "469" },
  { id: "2947", name: "Tagoloan", state_id: "469" },
  { id: "2948", name: "Villanueva", state_id: "469" },

  { id: "2949", name: "Aleosan", state_id: "470" },
  { id: "2950", name: "Antipas", state_id: "470" },
  { id: "2951", name: "Arakan", state_id: "470" },
  { id: "2952", name: "Kidapawan City", state_id: "470" },
  { id: "2953", name: "Midsayap", state_id: "470" },
  { id: "2954", name: "Maguindanao", state_id: "470" },
  { id: "2955", name: "Pikit", state_id: "470" },
  { id: "2956", name: "Pres. Roxas", state_id: "470" },
  { id: "2957", name: "Tulunan", state_id: "470" },

  { id: "2958", name: "Alabel", state_id: "471" },
  { id: "2959", name: "Glan", state_id: "471" },
  { id: "2960", name: "Maasim", state_id: "471" },
  { id: "2961", name: "Maitum", state_id: "471" },
  { id: "2962", name: "Malapatan", state_id: "471" },
  { id: "2963", name: "Sarangani", state_id: "471" },
  { id: "2964", name: "Tboli", state_id: "471" },
  { id: "2965", name: "General Santos City", state_id: "471" },

  { id: "2966", name: "Banga", state_id: "472" },
  { id: "2967", name: "Cotabato City", state_id: "472" },
  { id: "2968", name: "General Santos City", state_id: "472" },
  { id: "2969", name: "Lake Sebu", state_id: "472" },
  { id: "2970", name: "Polomolok", state_id: "472" },
  { id: "2971", name: "Surallah", state_id: "472" },
  { id: "2972", name: "Tampakan", state_id: "472" },
  { id: "2973", name: "T'boli", state_id: "472" },
  { id: "2974", name: "Sto. Niño", state_id: "472" },

  { id: "2975", name: "Bagumbayan", state_id: "473" },
  { id: "2976", name: "Columbio", state_id: "473" },
  { id: "2977", name: "Esperanza", state_id: "473" },
  { id: "2978", name: "Kalamansig", state_id: "473" },
  { id: "2979", name: "Lambayong", state_id: "473" },
  { id: "2980", name: "Lebak", state_id: "473" },
  { id: "2981", name: "Palimbang", state_id: "473" },
  { id: "2982", name: "Sen. Ninoy Aquino", state_id: "473" },
  { id: "2983", name: "Sultan Kudarat", state_id: "473" },
  { id: "2984", name: "Tacurong City", state_id: "473" },

  { id: "2985", name: "Banguingui", state_id: "474" },
  { id: "2986", name: "Hadji Panglima Tahil", state_id: "474" },
  { id: "2987", name: "Indanan", state_id: "474" },
  { id: "2988", name: "Jolo", state_id: "474" },
  { id: "2989", name: "Kalingalan Caluang", state_id: "474" },
  { id: "2990", name: "Lugus", state_id: "474" },
  { id: "2991", name: "Luuk", state_id: "474" },
  { id: "2992", name: "Maimbung", state_id: "474" },
  { id: "2993", name: "Old Panamao", state_id: "474" },
  { id: "2994", name: "Pandami", state_id: "474" },
  { id: "2995", name: "Panglima Estino", state_id: "474" },
  { id: "2996", name: "Parang", state_id: "474" },
  { id: "2997", name: "Patikul", state_id: "474" },
  { id: "2998", name: "Siasi", state_id: "474" },
  { id: "2999", name: "Talipao", state_id: "474" },
  { id: "3000", name: "Tapul", state_id: "474" },

  { id: "3001", name: "Alegria", state_id: "475" },
  { id: "3002", name: "Bacuag", state_id: "475" },
  { id: "3003", name: "Basilisa", state_id: "475" },
  { id: "3004", name: "Burgos", state_id: "475" },
  { id: "3005", name: "Claver", state_id: "475" },
  { id: "3006", name: "Dapa", state_id: "475" },
  { id: "3007", name: "Del Carmen", state_id: "475" },
  { id: "3008", name: "General Luna", state_id: "475" },
  { id: "3009", name: "Gigaquit", state_id: "475" },
  { id: "3010", name: "Mainit", state_id: "475" },
  { id: "3011", name: "Malimono", state_id: "475" },
  { id: "3012", name: "Pilar", state_id: "475" },
  { id: "3013", name: "Placer", state_id: "475" },
  { id: "3014", name: "San Benito", state_id: "475" },
  { id: "3015", name: "San Francisco", state_id: "475" },
  { id: "3016", name: "San Isidro", state_id: "475" },
  { id: "3017", name: "Santa Monica", state_id: "475" },
  { id: "3018", name: "Sison", state_id: "475" },
  { id: "3019", name: "Socorro", state_id: "475" },
  { id: "3020", name: "Surigao City", state_id: "475" },
  { id: "3021", name: "Tagana-an", state_id: "475" },
  { id: "3022", name: "Tubod", state_id: "475" },

  { id: "3023", name: "Barobo", state_id: "476" },
  { id: "3024", name: "Bayabas", state_id: "476" },
  { id: "3025", name: "Bislig City", state_id: "476" },
  { id: "3026", name: "Cagwait", state_id: "476" },
  { id: "3027", name: "Cantilan", state_id: "476" },
  { id: "3028", name: "Carmen", state_id: "476" },
  { id: "3029", name: "Carrascal", state_id: "476" },
  { id: "3030", name: "Cortes", state_id: "476" },
  { id: "3031", name: "Hinatuan", state_id: "476" },
  { id: "3032", name: "Lanuza", state_id: "476" },
  { id: "3033", name: "Lianga", state_id: "476" },
  { id: "3034", name: "Lingig", state_id: "476" },
  { id: "3035", name: "Madrid", state_id: "476" },
  { id: "3036", name: "Marihatag", state_id: "476" },
  { id: "3037", name: "San Agustin", state_id: "476" },
  { id: "3038", name: "San Miguel", state_id: "476" },
  { id: "3039", name: "Tagbina", state_id: "476" },
  { id: "3040", name: "Tago", state_id: "476" },
  { id: "3041", name: "Tandag City", state_id: "476" },

  { id: "3042", name: "Caloocan City", state_id: "477" },
  { id: "3043", name: "Las Piñas City", state_id: "477" },
  { id: "3044", name: "Makati City", state_id: "477" },
  { id: "3045", name: "Malabon City", state_id: "477" },
  { id: "3046", name: "Mandaluyong City", state_id: "477" },
  { id: "3047", name: "Manila City", state_id: "477" },
  { id: "3048", name: "Marikina City", state_id: "477" },
  { id: "3049", name: "Muntinlupa City", state_id: "477" },
  { id: "3050", name: "Navotas City", state_id: "477" },
  { id: "3051", name: "Parañaque City", state_id: "477" },
  { id: "3052", name: "Pasay City", state_id: "477" },
  { id: "3053", name: "Pasig City", state_id: "477" },
  { id: "3054", name: "Pateros", state_id: "477" },
  { id: "3055", name: "Quezon City", state_id: "477" },
  { id: "3056", name: "San Juan City", state_id: "477" },
  { id: "3057", name: "Taguig City", state_id: "477" },
  { id: "3058", name: "Valenzuela City", state_id: "477" },

  { id: "3059", name: "Bongao", state_id: "478" },
  { id: "3060", name: "Languyan", state_id: "478" },
  { id: "3061", name: "Mapun", state_id: "478" },
  { id: "3062", name: "Panglima Sugala", state_id: "478" },
  { id: "3063", name: "Sapa-Sapa", state_id: "478" },
  { id: "3064", name: "Simunul", state_id: "478" },
  { id: "3065", name: "Sitangkai", state_id: "478" },
  { id: "3066", name: "South Ubian", state_id: "478" },
  { id: "3067", name: "Tandubas", state_id: "478" },
  { id: "3068", name: "Turtle Islands", state_id: "478" },

  { id: "3069", name: "Bacungan", state_id: "479" },
  { id: "3070", name: "Baliguian", state_id: "479" },
  { id: "3071", name: "Dapitan City", state_id: "479" },
  { id: "3072", name: "Dipolog City", state_id: "479" },
  { id: "3073", name: "Godod", state_id: "479" },
  { id: "3074", name: "Gutalac", state_id: "479" },
  { id: "3075", name: "Jose Dalman", state_id: "479" },
  { id: "3076", name: "Kalawit", state_id: "479" },
  { id: "3077", name: "Katipunan", state_id: "479" },
  { id: "3078", name: "La Libertad", state_id: "479" },
  { id: "3079", name: "Labason", state_id: "479" },
  { id: "3080", name: "Liloy", state_id: "479" },
  { id: "3081", name: "Manukan", state_id: "479" },
  { id: "3082", name: "Mutia", state_id: "479" },
  { id: "3083", name: "Piñan", state_id: "479" },
  { id: "3084", name: "Polanco", state_id: "479" },
  { id: "3085", name: "President Manuel A. Roxas", state_id: "479" },
  { id: "3086", name: "Rizal", state_id: "479" },
  { id: "3087", name: "Salug", state_id: "479" },
  { id: "3088", name: "Sergio Osmeña", state_id: "479" },
  { id: "3089", name: "Siayan", state_id: "479" },
  { id: "3090", name: "Sibuco", state_id: "479" },
  { id: "3091", name: "Sibutad", state_id: "479" },
  { id: "3092", name: "Sindangan", state_id: "479" },
  { id: "3093", name: "Siocon", state_id: "479" },
  { id: "3094", name: "Sirawai", state_id: "479" },
  { id: "3095", name: "Tampilisan", state_id: "479" },

  { id: "3096", name: "Aurora", state_id: "480" },
  { id: "3097", name: "Bayog", state_id: "480" },
  { id: "3098", name: "Dimataling", state_id: "480" },
  { id: "3099", name: "Dinas", state_id: "480" },
  { id: "3100", name: "Dumalinao", state_id: "480" },
  { id: "3101", name: "Dumingag", state_id: "480" },
  { id: "3102", name: "Guipos", state_id: "480" },
  { id: "3103", name: "Josefina", state_id: "480" },
  { id: "3104", name: "Kumalarang", state_id: "480" },
  { id: "3105", name: "Labangan", state_id: "480" },
  { id: "3106", name: "Lakewood", state_id: "480" },
  { id: "3107", name: "Lapuyan", state_id: "480" },
  { id: "3108", name: "Mahayag", state_id: "480" },
  { id: "3109", name: "Margosatubig", state_id: "480" },
  { id: "3110", name: "Midsalip", state_id: "480" },
  { id: "3111", name: "Molave", state_id: "480" },
  { id: "3112", name: "Pagadian City", state_id: "480" },
  { id: "3113", name: "Pitogo", state_id: "480" },
  { id: "3114", name: "Ramon Magsaysay", state_id: "480" },
  { id: "3115", name: "San Miguel", state_id: "480" },
  { id: "3116", name: "San Pablo", state_id: "480" },
  { id: "3117", name: "Sominot", state_id: "480" },
  { id: "3118", name: "Tabina", state_id: "480" },
  { id: "3119", name: "Tambulig", state_id: "480" },
  { id: "3120", name: "Tigbao", state_id: "480" },
  { id: "3121", name: "Tukuran", state_id: "480" },
  { id: "3122", name: "Vincenzo A. Sagun", state_id: "480" },
  { id: "3123", name: "Zamboanga City", state_id: "480" },

  { id: "3124", name: "Alicia", state_id: "481" },
  { id: "3125", name: "Buug", state_id: "481" },
  { id: "3126", name: "Diplahan", state_id: "481" },
  { id: "3127", name: "Imelda", state_id: "481" },
  { id: "3128", name: "Ipil", state_id: "481" },
  { id: "3129", name: "Kabasalan", state_id: "481" },
  { id: "3130", name: "Mabuhay", state_id: "481" },
  { id: "3131", name: "Malangas", state_id: "481" },
  { id: "3132", name: "Naga", state_id: "481" },
  { id: "3133", name: "Olutanga", state_id: "481" },
  { id: "3134", name: "Payao", state_id: "481" },
  { id: "3135", name: "Roseller Lim", state_id: "481" },
  { id: "3136", name: "Siay", state_id: "481" },
  { id: "3137", name: "Talusan", state_id: "481" },
  { id: "3138", name: "Titay", state_id: "481" },
  { id: "3139", name: "Tungawan", state_id: "481" },

  { id: "3140", name: "Kandy", state_id: "501" },
  { id: "3141", name: "Matale", state_id: "501" },
  { id: "3142", name: "Nuwara Eliya", state_id: "501" },

  { id: "3143", name: "Ampara", state_id: "502" },
  { id: "3144", name: "Batticaloa", state_id: "502" },
  { id: "3145", name: "Trincomalee", state_id: "502" },

  { id: "3146", name: "Jaffna", state_id: "503" },
  { id: "3147", name: "Kilinochchi", state_id: "503" },
  { id: "3148", name: "Mannar", state_id: "503" },
  { id: "3149", name: "Mullaitivu", state_id: "503" },
  { id: "3150", name: "Vavuniya", state_id: "503" },

  { id: "3151", name: "Anuradhapura", state_id: "504" },
  { id: "3152", name: "Polonnaruwa", state_id: "504" },

  { id: "3153", name: "Kurunegala", state_id: "505" },
  { id: "3154", name: "Puttalam", state_id: "505" },

  { id: "3155", name: "Kegalle", state_id: "506" },
  { id: "3156", name: "Ratnapura", state_id: "506" },

  { id: "3157", name: "Galle", state_id: "507" },
  { id: "3158", name: "Hambantota", state_id: "507" },
  { id: "3159", name: "Matara", state_id: "507" },

  { id: "3160", name: "Badulla", state_id: "508" },
  { id: "3161", name: "Monaragala", state_id: "508" },

  { id: "3162", name: "Colombo", state_id: "509" },
  { id: "3163", name: "Gampaha", state_id: "509" },
  { id: "3164", name: "Kalutara", state_id: "509" },

  { id: "3165", name: "Dhaka", state_id: "601" },
  { id: "3166", name: "Faridpur", state_id: "601" },
  { id: "3167", name: "Gazipur", state_id: "601" },
  { id: "3168", name: "Gopalganj", state_id: "601" },
  { id: "3169", name: "Kishoreganj", state_id: "601" },
  { id: "3170", name: "Madaripur", state_id: "601" },
  { id: "3171", name: "Manikganj", state_id: "601" },
  { id: "3172", name: "Munshiganj", state_id: "601" },
  { id: "3173", name: "Narayanganj", state_id: "601" },
  { id: "3174", name: "Narsingdi", state_id: "601" },
  { id: "3175", name: "Rajbari", state_id: "601" },
  { id: "3176", name: "Shariatpur", state_id: "601" },
  { id: "3177", name: "Tangail", state_id: "601" },

  { id: "3178", name: "Bandarban", state_id: "602" },
  { id: "3179", name: "Brahmanbaria", state_id: "602" },
  { id: "3180", name: "Chandpur", state_id: "602" },
  { id: "3181", name: "Chattogram", state_id: "602" },
  { id: "3182", name: "Cox's Bazar", state_id: "602" },
  { id: "3183", name: "Cumilla", state_id: "602" },
  { id: "3184", name: "Feni", state_id: "602" },
  { id: "3185", name: "Khagrachari", state_id: "602" },
  { id: "3186", name: "Lakshmipur", state_id: "602" },
  { id: "3187", name: "Noakhali", state_id: "602" },
  { id: "3188", name: "Rangamati", state_id: "602" },

  { id: "3189", name: "Bagerhat", state_id: "603" },
  { id: "3190", name: "Chuadanga", state_id: "603" },
  { id: "3191", name: "Jashore", state_id: "603" },
  { id: "3192", name: "Jhenaidah", state_id: "603" },
  { id: "3193", name: "Khulna", state_id: "603" },
  { id: "3194", name: "Kushtia", state_id: "603" },
  { id: "3195", name: "Magura", state_id: "603" },
  { id: "3196", name: "Meherpur", state_id: "603" },
  { id: "3197", name: "Narail", state_id: "603" },
  { id: "3198", name: "Satkhira", state_id: "603" },

  { id: "3199", name: "Bogura", state_id: "604" },
  { id: "3200", name: "Joypurhat", state_id: "604" },
  { id: "3201", name: "Naogaon", state_id: "604" },
  { id: "3202", name: "Natore", state_id: "604" },
  { id: "3203", name: "Chapainawabganj", state_id: "604" },
  { id: "3204", name: "Pabna", state_id: "604" },
  { id: "3205", name: "Rajshahi", state_id: "604" },
  { id: "3206", name: "Sirajganj", state_id: "604" },

  { id: "3207", name: "Habiganj", state_id: "605" },
  { id: "3208", name: "Moulvibazar", state_id: "605" },
  { id: "3209", name: "Sunamganj", state_id: "605" },
  { id: "3210", name: "Sylhet", state_id: "605" },

  { id: "3211", name: "Barishal", state_id: "606" },
  { id: "3212", name: "Barguna", state_id: "606" },
  { id: "3213", name: "Bhola", state_id: "606" },
  { id: "3214", name: "Jhalokathi", state_id: "606" },
  { id: "3215", name: "Patuakhali", state_id: "606" },
  { id: "3216", name: "Pirojpur", state_id: "606" },

  { id: "3217", name: "Dinajpur", state_id: "607" },
  { id: "3218", name: "Gaibandha", state_id: "607" },
  { id: "3219", name: "Kurigram", state_id: "607" },
  { id: "3220", name: "Lalmonirhat", state_id: "607" },
  { id: "3221", name: "Nilphamari", state_id: "607" },
  { id: "3222", name: "Panchagarh", state_id: "607" },
  { id: "3223", name: "Rangpur", state_id: "607" },
  { id: "3224", name: "Thakurgaon", state_id: "607" },

  { id: "3225", name: "Jamalpur", state_id: "608" },
  { id: "3226", name: "Mymensingh", state_id: "608" },
  { id: "3227", name: "Netrokona", state_id: "608" },
  { id: "3228", name: "Sherpur", state_id: "608" },
];

export const police_stations = [
  { id: "20001", name: "Durbar Marg Police Station", city_id: "1001" },
  { id: "20002", name: "Pokhara Police Station", city_id: "1002" },
  { id: "20003", name: "Biratnagar Police Station", city_id: "1003" },
  { id: "20004", name: "Janakpur Police Station", city_id: "1004" },

  { id: "20005", name: "University Town Police Station", city_id: "2001" },
  { id: "20006", name: "Anarkali Police Station", city_id: "2002" },
  { id: "20007", name: "Clifton Police Station", city_id: "2003" },
  { id: "20008", name: "Civil Lines Police Station", city_id: "2004" },

  { id: "20009", name: "Worli Police Station", city_id: "3001" },
  { id: "20010", name: "Park Street Police Station", city_id: "3002" },
  { id: "20011", name: "Hazratganj Police Station", city_id: "3003" },
  { id: "20012", name: "T. Nagar Police Station", city_id: "3004" },

  { id: "20013", name: "Angeles City Police Station", city_id: "4001" },
  { id: "20014", name: "Quezon City Police Station", city_id: "4002" },
  { id: "20015", name: "Iloilo City Police Station", city_id: "4003" },
  { id: "20016", name: "Davao City Police Station", city_id: "4004" },

  { id: "20017", name: "Bambalapitiya Police Station", city_id: "5001" },
  { id: "20018", name: "Galle Police Station", city_id: "5002" },
  { id: "20019", name: "Kandy Police Station", city_id: "5003" },
  { id: "20020", name: "Batticaloa Police Station", city_id: "5004" },

  { id: "20021", name: "Ramna Police Station", city_id: "6001" },
  { id: "20022", name: "Kotwali Police Station", city_id: "6002" },
  { id: "20023", name: "Khalishpur Police Station", city_id: "6003" },
  { id: "20024", name: "Boalia Police Station", city_id: "6004" },
];

export const post_offices = [
  { id: "30001", name: "Kathmandu GPO", police_station_id: "20001" },
  { id: "30002", name: "Pokhara GPO", police_station_id: "20002" },
  { id: "30003", name: "Biratnagar GPO", police_station_id: "20003" },
  { id: "30004", name: "Janakpur GPO", police_station_id: "20004" },

  { id: "30005", name: "Peshawar GPO", police_station_id: "20005" },
  { id: "30006", name: "Lahore GPO", police_station_id: "20006" },
  { id: "30007", name: "Karachi GPO", police_station_id: "20007" },
  { id: "30008", name: "Quetta GPO", police_station_id: "20008" },

  { id: "30009", name: "Mumbai GPO", police_station_id: "20009" },
  { id: "30010", name: "Kolkata GPO", police_station_id: "20010" },
  { id: "30011", name: "Lucknow GPO", police_station_id: "20011" },
  { id: "30012", name: "Chennai GPO", police_station_id: "20012" },

  { id: "30013", name: "Angeles City Post Office", police_station_id: "20013" },
  { id: "30014", name: "Quezon City Post Office", police_station_id: "20014" },
  { id: "30015", name: "Iloilo City Post Office", police_station_id: "20015" },
  { id: "30016", name: "Davao City Post Office", police_station_id: "20016" },

  { id: "30017", name: "Colombo GPO", police_station_id: "20017" },
  { id: "30018", name: "Galle Post Office", police_station_id: "20018" },
  { id: "30019", name: "Kandy Post Office", police_station_id: "20019" },
  { id: "30020", name: "Batticaloa Post Office", police_station_id: "20020" },

  { id: "30021", name: "Dhaka GPO", police_station_id: "20021" },
  { id: "30022", name: "Chittagong GPO", police_station_id: "20022" },
  { id: "30023", name: "Khulna GPO", police_station_id: "20023" },
  { id: "30024", name: "Rajshahi GPO", police_station_id: "20024" },
];

export const countryCode = [
  { id: "1", name: "Afghanistan", code: "+93", shortName: "AF" },
  { id: "2", name: "Albania", code: "+355", shortName: "AL" },
  { id: "3", name: "Algeria", code: "+213", shortName: "DZ" },
  { id: "4", name: "Andorra", code: "+376", shortName: "AD" },
  { id: "5", name: "Angola", code: "+244", shortName: "AO" },
  { id: "6", name: "Antigua and Barbuda", code: "+1-268", shortName: "AG" },
  { id: "7", name: "Argentina", code: "+54", shortName: "AR" },
  { id: "8", name: "Armenia", code: "+374", shortName: "AM" },
  { id: "9", name: "Australia", code: "+61", shortName: "AU" },
  { id: "10", name: "Austria", code: "+43", shortName: "AT" },
  { id: "11", name: "Azerbaijan", code: "+994", shortName: "AZ" },
  { id: "12", name: "Bahamas", code: "+1-242", shortName: "BS" },
  { id: "13", name: "Bahrain", code: "+973", shortName: "BH" },
  { id: "14", name: "Bangladesh", code: "+880", shortName: "BD" },
  { id: "15", name: "Barbados", code: "+1-246", shortName: "BB" },
  { id: "16", name: "Belarus", code: "+375", shortName: "BY" },
  { id: "17", name: "Belgium", code: "+32", shortName: "BE" },
  { id: "18", name: "Belize", code: "+501", shortName: "BZ" },
  { id: "19", name: "Benin", code: "+229", shortName: "BJ" },
  { id: "20", name: "Bhutan", code: "+975", shortName: "BT" },
  { id: "21", name: "Bolivia", code: "+591", shortName: "BO" },
  { id: "22", name: "Bosnia and Herzegovina", code: "+387", shortName: "BA" },
  { id: "23", name: "Botswana", code: "+267", shortName: "BW" },
  { id: "24", name: "Brazil", code: "+55", shortName: "BR" },
  { id: "25", name: "Brunei", code: "+673", shortName: "BN" },
  { id: "26", name: "Bulgaria", code: "+359", shortName: "BG" },
  { id: "27", name: "Burkina Faso", code: "+226", shortName: "BF" },
  { id: "28", name: "Burundi", code: "+257", shortName: "BI" },
  { id: "29", name: "Cabo Verde", code: "+238", shortName: "CV" },
  { id: "30", name: "Cambodia", code: "+855", shortName: "KH" },
  { id: "31", name: "Cameroon", code: "+237", shortName: "CM" },
  { id: "32", name: "Canada", code: "+1", shortName: "CA" },
  { id: "33", name: "Central African Republic", code: "+236", shortName: "CF" },
  { id: "34", name: "Chad", code: "+235", shortName: "TD" },
  { id: "35", name: "Chile", code: "+56", shortName: "CL" },
  { id: "36", name: "China", code: "+86", shortName: "CN" },
  { id: "37", name: "Colombia", code: "+57", shortName: "CO" },
  { id: "38", name: "Comoros", code: "+269", shortName: "KM" },
  {
    id: "39",
    name: "Congo (Congo-Brazzaville)",
    code: "+242",
    shortName: "CG",
  },
  { id: "40", name: "Costa Rica", code: "+506", shortName: "CR" },
  { id: "41", name: "Croatia", code: "+385", shortName: "HR" },
  { id: "42", name: "Cuba", code: "+53", shortName: "CU" },
  { id: "43", name: "Cyprus", code: "+357", shortName: "CY" },
  { id: "44", name: "Czechia (Czech Republic)", code: "+420", shortName: "CZ" },
  { id: "45", name: "Denmark", code: "+45", shortName: "DK" },
  { id: "46", name: "Djibouti", code: "+253", shortName: "DJ" },
  { id: "47", name: "Dominica", code: "+1-767", shortName: "DM" },
  { id: "48", name: "Dominican Republic", code: "+1-809", shortName: "DO" },
  { id: "49", name: "East Timor (Timor-Leste)", code: "+670", shortName: "TL" },
  { id: "50", name: "Ecuador", code: "+593", shortName: "EC" },
  { id: "51", name: "Egypt", code: "+20", shortName: "EG" },
  { id: "52", name: "El Salvador", code: "+503", shortName: "SV" },
  { id: "53", name: "Equatorial Guinea", code: "+240", shortName: "GQ" },
  { id: "54", name: "Eritrea", code: "+291", shortName: "ER" },
  { id: "55", name: "Estonia", code: "+372", shortName: "EE" },
  { id: "56", name: "Eswatini", code: "+268", shortName: "SZ" },
  { id: "57", name: "Ethiopia", code: "+251", shortName: "ET" },
  { id: "58", name: "Fiji", code: "+679", shortName: "FJ" },
  { id: "59", name: "Finland", code: "+358", shortName: "FI" },
  { id: "60", name: "France", code: "+33", shortName: "FR" },
  { id: "61", name: "Gabon", code: "+241", shortName: "GA" },
  { id: "62", name: "Gambia", code: "+220", shortName: "GM" },
  { id: "63", name: "Georgia", code: "+995", shortName: "GE" },
  { id: "64", name: "Germany", code: "+49", shortName: "DE" },
  { id: "65", name: "Ghana", code: "+233", shortName: "GH" },
  { id: "66", name: "Greece", code: "+30", shortName: "GR" },
  { id: "67", name: "Grenada", code: "+1-473", shortName: "GD" },
  { id: "68", name: "Guatemala", code: "+502", shortName: "GT" },
  { id: "69", name: "Guinea", code: "+224", shortName: "GN" },
  { id: "70", name: "Guinea-Bissau", code: "+245", shortName: "GW" },
  { id: "71", name: "Guyana", code: "+592", shortName: "GY" },
  { id: "72", name: "Haiti", code: "+509", shortName: "HT" },
  { id: "73", name: "Honduras", code: "+504", shortName: "HN" },
  { id: "74", name: "Hungary", code: "+36", shortName: "HU" },
  { id: "75", name: "Iceland", code: "+354", shortName: "IS" },
  { id: "76", name: "India", code: "+91", shortName: "IN" },
  { id: "77", name: "Indonesia", code: "+62", shortName: "ID" },
  { id: "78", name: "Iran", code: "+98", shortName: "IR" },
  { id: "79", name: "Iraq", code: "+964", shortName: "IQ" },
  { id: "80", name: "Ireland", code: "+353", shortName: "IE" },
  { id: "81", name: "Israel", code: "+972", shortName: "IL" },
  { id: "82", name: "Italy", code: "+39", shortName: "IT" },
  { id: "83", name: "Jamaica", code: "+1-876", shortName: "JM" },
  { id: "84", name: "Japan", code: "+81", shortName: "JP" },
  { id: "85", name: "Jordan", code: "+962", shortName: "JO" },
  { id: "86", name: "Kazakhstan", code: "+7", shortName: "KZ" },
  { id: "87", name: "Kenya", code: "+254", shortName: "KE" },
  { id: "88", name: "Kiribati", code: "+686", shortName: "KI" },
  { id: "89", name: "Korea (North)", code: "+850", shortName: "KP" },
  { id: "90", name: "Korea (South)", code: "+82", shortName: "KR" },
  { id: "91", name: "Kosovo", code: "+383", shortName: "XK" },
  { id: "92", name: "Kuwait", code: "+965", shortName: "KW" },
  { id: "93", name: "Kyrgyzstan", code: "+996", shortName: "KG" },
  { id: "94", name: "Laos", code: "+856", shortName: "LA" },
  { id: "95", name: "Latvia", code: "+371", shortName: "LV" },
  { id: "96", name: "Lebanon", code: "+961", shortName: "LB" },
  { id: "97", name: "Lesotho", code: "+266", shortName: "LS" },
  { id: "98", name: "Liberia", code: "+231", shortName: "LR" },
  { id: "99", name: "Libya", code: "+218", shortName: "LY" },
  { id: "100", name: "Liechtenstein", code: "+423", shortName: "LI" },
  { id: "101", name: "Lithuania", code: "+370", shortName: "LT" },
  { id: "102", name: "Luxembourg", code: "+352", shortName: "LU" },
  { id: "103", name: "Madagascar", code: "+261", shortName: "MG" },
  { id: "104", name: "Malawi", code: "+265", shortName: "MW" },
  { id: "105", name: "Malaysia", code: "+60", shortName: "MY" },
  { id: "106", name: "Maldives", code: "+960", shortName: "MV" },
  { id: "107", name: "Mali", code: "+223", shortName: "ML" },
  { id: "108", name: "Malta", code: "+356", shortName: "MT" },
  { id: "109", name: "Marshall Islands", code: "+692", shortName: "MH" },
  { id: "110", name: "Mauritania", code: "+222", shortName: "MR" },
  { id: "111", name: "Mauritius", code: "+230", shortName: "MU" },
  { id: "112", name: "Mexico", code: "+52", shortName: "MX" },
  { id: "113", name: "Micronesia", code: "+691", shortName: "FM" },
  { id: "114", name: "Moldova", code: "+373", shortName: "MD" },
  { id: "115", name: "Monaco", code: "+377", shortName: "MC" },
  { id: "116", name: "Mongolia", code: "+976", shortName: "MN" },
  { id: "117", name: "Montenegro", code: "+382", shortName: "ME" },
  { id: "118", name: "Morocco", code: "+212", shortName: "MA" },
  { id: "119", name: "Mozambique", code: "+258", shortName: "MZ" },
  { id: "120", name: "Myanmar (Burma)", code: "+95", shortName: "MM" },
  { id: "121", name: "Namibia", code: "+264", shortName: "NA" },
  { id: "122", name: "Nauru", code: "+674", shortName: "NR" },
  { id: "123", name: "Nepal", code: "+977", shortName: "NP" },
  { id: "124", name: "Netherlands", code: "+31", shortName: "NL" },
  { id: "125", name: "New Zealand", code: "+64", shortName: "NZ" },
  { id: "126", name: "Nicaragua", code: "+505", shortName: "NI" },
  { id: "127", name: "Niger", code: "+227", shortName: "NE" },
  { id: "128", name: "Nigeria", code: "+234", shortName: "NG" },
  { id: "129", name: "North Macedonia", code: "+389", shortName: "MK" },
  { id: "130", name: "Norway", code: "+47", shortName: "NO" },
  { id: "131", name: "Oman", code: "+968", shortName: "OM" },
  { id: "132", name: "Pakistan", code: "+92", shortName: "PK" },
  { id: "133", name: "Palau", code: "+680", shortName: "PW" },
  { id: "134", name: "Panama", code: "+507", shortName: "PA" },
  { id: "135", name: "Papua New Guinea", code: "+675", shortName: "PG" },
  { id: "136", name: "Paraguay", code: "+595", shortName: "PY" },
  { id: "137", name: "Peru", code: "+51", shortName: "PE" },
  { id: "138", name: "Philippine", code: "+63", shortName: "PH" },
  { id: "139", name: "Poland", code: "+48", shortName: "PL" },
  { id: "140", name: "Portugal", code: "+351", shortName: "PT" },
  { id: "141", name: "Qatar", code: "+974", shortName: "QA" },
  { id: "142", name: "Romania", code: "+40", shortName: "RO" },
  { id: "143", name: "Russia", code: "+7", shortName: "RU" },
  { id: "144", name: "Rwanda", code: "+250", shortName: "RW" },
  { id: "145", name: "Saint Kitts and Nevis", code: "+1-869", shortName: "KN" },
  { id: "146", name: "Saint Lucia", code: "+1-758", shortName: "LC" },
  {
    id: "147",
    name: "Saint Vincent and the Grenadines",
    code: "+1-784",
    shortName: "VC",
  },
  { id: "148", name: "Samoa", code: "+685", shortName: "WS" },
  { id: "149", name: "San Marino", code: "+378", shortName: "SM" },
  { id: "150", name: "Sao Tome and Principe", code: "+239", shortName: "ST" },
  { id: "151", name: "Saudi Arabia", code: "+966", shortName: "SA" },
  { id: "152", name: "Senegal", code: "+221", shortName: "SN" },
  { id: "153", name: "Serbia", code: "+381", shortName: "RS" },
  { id: "154", name: "Seychelles", code: "+248", shortName: "SC" },
  { id: "155", name: "Sierra Leone", code: "+232", shortName: "SL" },
  { id: "156", name: "Singapore", code: "+65", shortName: "SG" },
  { id: "157", name: "Slovakia", code: "+421", shortName: "SK" },
  { id: "158", name: "Slovenia", code: "+386", shortName: "SI" },
  { id: "159", name: "Solomon Islands", code: "+677", shortName: "SB" },
  { id: "160", name: "Somalia", code: "+252", shortName: "SO" },
  { id: "161", name: "South Africa", code: "+27", shortName: "ZA" },
  { id: "162", name: "South Sudan", code: "+211", shortName: "SS" },
  { id: "163", name: "Spain", code: "+34", shortName: "ES" },
  { id: "164", name: "Sri Lanka", code: "+94", shortName: "LK" },
  { id: "165", name: "Sudan", code: "+249", shortName: "SD" },
  { id: "166", name: "Suriname", code: "+597", shortName: "SR" },
  { id: "167", name: "Sweden", code: "+46", shortName: "SE" },
  { id: "168", name: "Switzerland", code: "+41", shortName: "CH" },
  { id: "169", name: "Syria", code: "+963", shortName: "SY" },
  { id: "170", name: "Taiwan", code: "+886", shortName: "TW" },
  { id: "171", name: "Tajikistan", code: "+992", shortName: "TJ" },
  { id: "172", name: "Tanzania", code: "+255", shortName: "TZ" },
  { id: "173", name: "Thailand", code: "+66", shortName: "TH" },
  { id: "174", name: "Timor-Leste", code: "+670", shortName: "TL" },
  { id: "175", name: "Togo", code: "+228", shortName: "TG" },
  { id: "176", name: "Tonga", code: "+676", shortName: "TO" },
  { id: "177", name: "Trinidad and Tobago", code: "+1-868", shortName: "TT" },
  { id: "178", name: "Tunisia", code: "+216", shortName: "TN" },
  { id: "179", name: "Turkey", code: "+90", shortName: "TR" },
  { id: "180", name: "Turkmenistan", code: "+993", shortName: "TM" },
  { id: "181", name: "Tuvalu", code: "+688", shortName: "TV" },
  { id: "182", name: "Uganda", code: "+256", shortName: "UG" },
  { id: "183", name: "Ukraine", code: "+380", shortName: "UA" },
  { id: "184", name: "United Arab Emirates", code: "+971", shortName: "AE" },
  { id: "185", name: "United Kingdom", code: "+44", shortName: "GB" },
  { id: "186", name: "United States", code: "+1", shortName: "US" },
  { id: "187", name: "Uruguay", code: "+598", shortName: "UY" },
  { id: "188", name: "Uzbekistan", code: "+998", shortName: "UZ" },
  { id: "189", name: "Vanuatu", code: "+678", shortName: "VU" },
  { id: "190", name: "Vatican City", code: "+379", shortName: "VA" },
  { id: "191", name: "Venezuela", code: "+58", shortName: "VE" },
  { id: "192", name: "Vietnam", code: "+84", shortName: "VN" },
  { id: "193", name: "Yemen", code: "+967", shortName: "YE" },
  { id: "194", name: "Zambia", code: "+260", shortName: "ZM" },
  { id: "195", name: "Zimbabwe", code: "+263", shortName: "ZW" },
];

export const religion = [
  {
    id: "1",
    name: "Islam",
  },
  {
    id: "2",
    name: "Hinduism",
  },
  {
    id: "3",
    name: "Christianity",
  },
  {
    id: "4",
    name: "Buddhism",
  },
];

export const hiringPositions = [
  { id: 1, name: "Account Super Intendent" },
  { id: 2, name: "Accounting Assistant" },
  { id: 3, name: "Accounting Clerk / General" },
  { id: 4, name: "Accounting Executive" },
  { id: 5, name: "Administrative Affairs Clerks" },
  { id: 6, name: "Admin Assistant" },
  { id: 7, name: "Administrative Assistant" },
  { id: 8, name: "Administration Coord." },
  { id: 9, name: "Administration Executive" },
  { id: 10, name: "Administrative Officer" },
  { id: 11, name: "Administrative Superintendent" },
  { id: 12, name: "Administrative Supervisor Assistant" },
  { id: 13, name: "Administrative Training Superintendent" },
  { id: 14, name: "Administrator" },
  { id: 15, name: "Administrator / General" },
  { id: 16, name: "Administration Worker" },
  { id: 17, name: "Advertising Representative" },
  { id: 18, name: "Assistant Accountant" },
  { id: 19, name: "Assistant Admin Officer" },
  { id: 20, name: "Asst Admin Superintendent" },
  { id: 21, name: "Asst Security Incharge" },
  { id: 22, name: "Attendance Clerk" },
  { id: 23, name: "Auditing Clerk" },
  { id: 24, name: "Building Cleaner (Outside)" },
  { id: 25, name: "Building Supervisor" },
  { id: 26, name: "Car Electrician Assistant" },
  { id: 27, name: "Car Rental Clerk" },
  { id: 28, name: "Cash Desk Clerk" },
  { id: 29, name: "Cashier" },
  { id: 30, name: "Cashier Supervisor" },
  { id: 31, name: "Civil Registers Clerk" },
  { id: 32, name: "Cleaner / General" },
  { id: 33, name: "Cleaning Worker" },
  { id: 34, name: "Clerical Supervisors" },
  { id: 35, name: "Clerk Assistant" },
  { id: 36, name: "Commercial Broker" },
  { id: 37, name: "Computer Data Entry Operator" },
  { id: 38, name: "Computer Operator" },
  { id: 39, name: "Content Developer" },
  { id: 40, name: "Contracts & Purchases- Assistance" },
  { id: 41, name: "Customer Service Representative" },
  { id: 42, name: "Customer Services Assistant" },
  { id: 43, name: "Data Entry Assistant" },
  { id: 44, name: "Data Entry Clerk" },
  { id: 45, name: "Deputy Customer Service" },
  { id: 46, name: "Document Scanning Clerk" },
  { id: 47, name: "Documents Clerk/Account" },
  { id: 48, name: "Domestic Supervisor" },
  { id: 49, name: "Employment Services Clerk" },
  { id: 50, name: "Event Coordinator" },
  { id: 51, name: "Excellent Clerk" },
  { id: 52, name: "Executive Assistant" },
  { id: 53, name: "Executive Personal Ass." },
  { id: 54, name: "Executive Secretary" },
  { id: 55, name: "Exhibitions Coordinator" },
  { id: 56, name: "Filer Assistant" },
  { id: 57, name: "Filer General" },
  { id: 58, name: "Filing Work Supervisor" },
  { id: 59, name: "Financial Representative" },
  { id: 60, name: "General Helper" },
  { id: 61, name: "General Services Professions" },
  { id: 62, name: "General Workers" },
  { id: 63, name: "Graphics Operator" },
  { id: 64, name: "Head Waiter/Waitress" },
  { id: 65, name: "Health Care Attendant" },
  { id: 66, name: "Help Desk Clerk" },
  { id: 67, name: "Helpdesk Operator" },
  { id: 68, name: "House Supervisor" },
  { id: 69, name: "House Supervisor Assistant" },
  { id: 70, name: "Household Appliance Electricin" },
  { id: 71, name: "Household Appliances Assembler" },
  { id: 72, name: "Housing Officer" },
  { id: 73, name: "HR Assistant" },
  { id: 74, name: "HR Coordinator" },
  { id: 75, name: "Human Resource Coordinator" },
  { id: 76, name: "Information Clerks" },
  { id: 77, name: "Lawyer Clerk" },
  { id: 78, name: "Legal Clerks" },
  { id: 79, name: "Library Clerk" },
  { id: 80, name: "Marketing Assistant" },
  { id: 81, name: "Medical Instruments Sales" },
  { id: 82, name: "Men Barber" },
  { id: 83, name: "Men Clothes Sales" },
  { id: 84, name: "Messengers Head" },
  { id: 85, name: "Metal Worker" },
  { id: 86, name: "Metallic Furniture Sales" },
  { id: 87, name: "Metals Sales" },
  { id: 88, name: "Office Clerks General" },
  { id: 89, name: "Office Supervisor" },
  { id: 90, name: "Office Worker" },
  { id: 91, name: "Paintings Sales" },
  { id: 92, name: "Passengers Service Clerk" },
  { id: 93, name: "Pavement Supervisor" },
  { id: 94, name: "Personal Assistant" },
  { id: 95, name: "Personal Affairs Supervisor" },
  { id: 96, name: "Personnel Affairs Clerk" },
  { id: 97, name: "Personnel Assistant" },
  { id: 98, name: "Pharmacist Assistant" },
  { id: 99, name: "Planning Assistant" },
  { id: 100, name: "Plant Operator" },
];
